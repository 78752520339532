import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import APIUrl from "../../APIUrl";
import AutoSuggestProducts from "../sub/AutoSuggestProducts";
import ProductsParserUtil from "../../util/ProductsParserUtil";
import { Link } from "react-router-dom";
import { getProducts } from "../../actions/products/products";
import "../../css/families/families.css";
import TableToolbar from "../sub/bootstrap/TableToolbar";
import ReactImageFallback from "react-image-fallback";

class Families extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      popSugg: false,
    };
  }

  getDesignation(product) {
    if (!product.designation) return product.sous_famille;

    if (
      product.designation.indexOf(process.env.REACT_APP_PRODUCT_DELIMITER) === 0
    )
      return product.designation;

    return product.designation.split(
      process.env.REACT_APP_PRODUCT_DELIMITER,
    )[0];
  }

  onSearch(value, products) {
    // Find the product and redirect to it
    for (let product of products) {
      if (product.ref === value.ref) {
        window.location.href =
          "#/home/products/mercurial/" +
          product.mercurial_id +
          "/" +
          encodeURI(product.famille) +
          "/" +
          product._id;
      }
    }
  }

  productsToSuggestions(products) {
    var suggestions = [];
    var alreadyUsedFamilies = {};

    for (let product of products) {
      var sugg = ProductsParserUtil.trim(product);

      if (!sugg && !alreadyUsedFamilies[product.sous_famille]) {
        sugg = product.sous_famille;
        alreadyUsedFamilies[product.sous_famille] = true;
      }

      if (sugg) suggestions.push(sugg);
    }

    return suggestions;
  }

  componentDidMount() {
    if (
      this.props.mercurials.length !== 0 &&
      this.props.products.length !== this.props.mercurials.productsLength
    ) {
      this.props.onGetProducts();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.mercurials.length !== 0 &&
      this.props.products.length !== this.props.mercurials.productsLength &&
      prevProps.products.length !== this.props.products.length
    ) {
      this.props.onGetProducts();
    }
  }

  render() {
    // No 'families'? No render
    if (this.props.products.length === 0) {
      return (
        <div className="alert alert-secondary" role="alert">
          <FormattedMessage id="Empty.Families" />
        </div>
      );
    }

    let suggestions2 = [];
    let mercurial_id;

    let favoriteFamilyKey = this.props.intl
      .formatMessage({ id: "Top.Products" })
      .toUpperCase();
    let familiesUniques = {};

    // Add dynamic favorite family
    familiesUniques[favoriteFamilyKey] = { mercurialId: null, fam: "0.FAV" };

    this.props.products.forEach((product) => {
      let obj = {};
      obj.designation = ProductsParserUtil.swapDesignationDelimiter(
        product.designation,
      );
      obj.caracteristiques = product.caracteristiques;
      obj.ref = product.ref;
      obj.mercurial_id = product.mercurial_id;
      obj.ref_frn = product.ref_frn;
      obj.famille = product.famille;
      obj.sous_famille = product.sous_famille;
      suggestions2.push(obj);

      mercurial_id = product.mercurial_id;

      // define families
      familiesUniques[product.famille] = {
        mercurialId: product.mercurial_id,
        fam: product.fam,
      };
    });

    // Update favorite family to add mercurial_id defined in previous loop
    familiesUniques[favoriteFamilyKey] = {
      mercurialId: mercurial_id,
      fam: "0.FAV",
    };

    let familiesNode = Object.keys(familiesUniques).map((famille) => {
      // Href link
      var imgSrc =
        famille === favoriteFamilyKey
          ? "/images/favorite_family.png"
          : APIUrl.getFamilyImg +
            familiesUniques[famille].mercurialId +
            "/FAM_" +
            familiesUniques[famille].fam.split(".")[0] +
            "?token=" +
            APIUrl.jwtToken;

      return (
        <div
          className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-4 mx-auto mw-400"
          key={famille}
        >
          <Link
            to={
              "/home/products/mercurial/" +
              familiesUniques[famille].mercurialId +
              "/" +
              encodeURI(famille)
            }
            className="nostyle-a"
          >
            <div className="card hoverable h-300">
              <div className="card-header text-center">
                <h6 className="text-info mb-0">
                  <strong>{famille}</strong>
                </h6>
              </div>
              <div className="h-350">
                <ReactImageFallback
                  src={imgSrc}
                  fallbackImage="/images/no_image_512.png"
                  initialImage="/images/loader.gif"
                  alt=""
                  className="d-block align-middle mx-auto blocFamilies"
                />
              </div>
            </div>
          </Link>
        </div>
      );
    });

    var familles = [];
    var suggestions = [];
    var filteredProducts = [];

    if (this.state.popSugg) {
      familles = Object.keys(familiesUniques);

      suggestions = suggestions.concat(familles);

      for (let famille of familles) {
        let ps = ProductsParserUtil.parseProducts(filteredProducts, famille);
        suggestions = suggestions.concat(this.productsToSuggestions(ps));
      }
    }

    return (
      <React.Fragment>
        <TableToolbar>
          <div className="mx-auto w-50">
            <AutoSuggestProducts
              id="search"
              name="search"
              onClick={(e) => this.setState({ popSugg: true })}
              onBlur={(e) => this.update("name", e.target.value)}
              onSuggestionSelected={(value) =>
                this.onSearch(value, this.props.products)
              }
              suggestions={suggestions2}
              placeholder={"Search.Product.Placeholder"}
            />
          </div>
        </TableToolbar>

        <div className="row">{familiesNode}</div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.products,
    mercurials: state.mercurials,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetProducts: () => dispatch(getProducts()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Families));
