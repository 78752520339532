import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Paginator from "../sub/Paginator";
import PrescriberRow from "./PrescriberRow";
import Roles from "../../enums/Roles";
import Util from "../../util/Util";
import CustomLabel from "../sub/CustomLabel";
import PrescriberModal from "./PrescriberModal";
import { getClient } from "../../actions/clients/clients";
import MenuButton from "../sub/bootstrap/MenuButton";

class Prescribers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,

      // filters
      nameFilter: "",
      client_typeFilter: this.props.client_typeFilter || "",
      typeFilter: "",
      addressFilter: "",
    };

    this.paginator = new Paginator(this);
  }

  openModal(client, target = "clientInfo") {
    const modal = (client) => {
      this.setState({
        modal: (
          <PrescriberModal
            isOpen={true}
            client={client}
            target={target}
            openModal={(client, target) => this.openModal(client, target)}
            close={() => this.closeModal()}
          />
        ),
      });
    };

    if (client && client._id) {
      this.props.onGetClient(client._id, (client) => modal(client));
    } else {
      modal(client);
    }
  }

  closeModal() {
    this.setState({ modal: null });
  }

  areResultsFiltered = () => {
    if (
      !Util.emptyString(this.state.nameFilter) ||
      !Util.emptyString(this.state.client_typeFilter) ||
      !Util.emptyString(this.state.typeFilter) ||
      !Util.emptyString(this.state.addressFilter)
    ) {
      return true;
    } else {
      return false;
    }
  };

  resetSearchFields() {
    this.setState({
      nameFilter: "",
      client_typeFilter: "",
      typeFilter: "",
      addressFilter: "",
    });
  }

  render() {
    const { clients, user, patient, collaborator } = this.props;

    let clientsType = this.props.prescriberTypes.map((prescriber) => {
      return (
        <option key={prescriber._id} value={prescriber._id}>
          {prescriber.name}
        </option>
      );
    });

    // No clients
    if ((!clients || clients.length === 0) && user.role === Roles.ADMIN) {
      return (
        <React.Fragment>
          <div className="row search-filters">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="form-inline">
                    <div className="alert alert-info w-100">
                      <FormattedMessage id="Empty.Client" />
                    </div>
                    <button
                      className="btn btn-info ml-auto"
                      onClick={(e) => this.openModal()}
                    >
                      <FormattedMessage id="Add.Client" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.state.modal}
        </React.Fragment>
      );
    } else if (
      (!clients || clients.length === 0) &&
      user.role === Roles.SALES_REP
    ) {
      return (
        <div className="alert alert-secondary mt-3" role="alert">
          <FormattedMessage id="Empty.Client.Collaborator" />
        </div>
      );
    }

    this.paginator.init();

    let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

    let i = 0;
    let clientsNode = clients.map((client) => {
      if (patient && patient.helpers_ids && patient.helpers_ids.length > 0) {
        if (patient.helpers_ids.includes(client._id)) return null;
      }

      if (
        patient &&
        patient.prescribers_ids &&
        patient.prescribers_ids.length > 0
      ) {
        if (patient.prescribers_ids.includes(client._id)) return null;
      }

      // Specific to collaborator assignments
      if (
        collaborator &&
        collaborator.prescribers &&
        collaborator.prescribers.length > 0
      ) {
        if (
          collaborator.prescribers.find((c) => c.prescriber_id === client._id)
        )
          return null;
      }

      if (this.state.nameFilter && this.state.nameFilter !== "") {
        if (
          client.name
            .toUpperCase()
            .indexOf(this.state.nameFilter.toUpperCase()) === -1 &&
          client.first_name
            .toUpperCase()
            .indexOf(this.state.nameFilter.toUpperCase()) === -1
        )
          return null;
      }

      if (this.state.client_typeFilter && this.state.client_typeFilter !== "") {
        if (
          !client.client_type ||
          client.client_type.indexOf(this.state.client_typeFilter) === -1
        )
          return null;
      }

      if (this.state.typeFilter && this.state.typeFilter !== "") {
        if (!client.type || client.type.indexOf(this.state.typeFilter) === -1)
          return null;
      }

      if (this.state.addressFilter && this.state.addressFilter !== "") {
        if (
          (!client.address ||
            client.address
              .toUpperCase()
              .indexOf(this.state.addressFilter.toUpperCase()) === -1) &&
          (!client.postal_code ||
            client.postal_code
              .toUpperCase()
              .indexOf(this.state.addressFilter.toUpperCase()) === -1) &&
          (!client.city ||
            client.city
              .toUpperCase()
              .indexOf(this.state.addressFilter.toUpperCase()) === -1)
        )
          return null;
      }

      if (client.client_type === "patient") return null;

      if (this.props.limit && ++i > this.props.limit) return null;

      if (!this.paginator.keep()) return null;

      return (
        <PrescriberRow
          key={client._id}
          client={client}
          displayAPIModal={(prescriber) =>
            this.openModal(prescriber, "apiConfiguration")
          }
          edit={(client, tabName) => this.openModal(client, tabName)}
          user={user}
          limit={this.props.limit && true}
          // delete={(client) => this.delete(client)}
        />
      );
    });

    // https://stackoverflow.com/questions/37308719/react-component-wait-for-required-props-to-render
    // https://zaiste.net/posts/javascript-destructuring-assignment-default-values/
    const { enabled: crmEnabled = false, software: crmSoftware = null } = this
      .props.company.crm
      ? this.props.company.crm
      : {};

    return (
      <React.Fragment>
        {!this.props.limit && (
          <div className="row search-filters">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="form-inline">
                    <CustomLabel
                      label={this.props.intl.formatMessage({ id: "Client" })}
                      htmlFor="search_client"
                      labelClassName="my-1 mr-2"
                    />
                    <input
                      id="search_client"
                      type="text"
                      className="form-control mr-sm-3"
                      placeholder={
                        this.props.intl.formatMessage({ id: "Name" }) +
                        " " +
                        this.props.intl.formatMessage({ id: "Or" }) +
                        " " +
                        this.props.intl.formatMessage({ id: "First.Name" })
                      }
                      onChange={(e) => {
                        this.setState({ nameFilter: e.target.value });
                      }}
                      disabled={disableFormInput}
                      value={this.state.nameFilter}
                    />
                    <CustomLabel
                      label={this.props.intl.formatMessage({ id: "Type" })}
                      htmlFor="search_type"
                      labelClassName="my-1 mr-2"
                    />
                    <select
                      id="search_type"
                      className="form-control mr-sm-3"
                      onChange={(e) => {
                        this.setState({ client_typeFilter: e.target.value });
                      }}
                      disabled={
                        disableFormInput || this.props.client_typeFilter
                      }
                      value={this.state.client_typeFilter}
                    >
                      <option value="">
                        {this.props.intl.formatMessage({ id: "All" })}
                      </option>
                      <option value="helper">
                        {this.props.intl.formatMessage({ id: "Client.Helper" })}
                      </option>
                      <option value="prescriber">
                        {this.props.intl.formatMessage({
                          id: "Client.Prescriber",
                        })}
                      </option>
                    </select>
                    <CustomLabel
                      label={this.props.intl.formatMessage({
                        id: "Client.Label",
                      })}
                      htmlFor="search_label"
                      labelClassName="my-1 mr-2"
                    />
                    <select
                      id="search_label"
                      className="form-control mr-sm-3"
                      onChange={(e) => {
                        this.setState({ typeFilter: e.target.value });
                      }}
                      disabled={disableFormInput}
                      value={this.state.typeFilter}
                    >
                      <option value="">
                        {this.props.intl.formatMessage({ id: "All" })}
                      </option>
                      {clientsType}
                    </select>
                    <CustomLabel
                      label={this.props.intl.formatMessage({ id: "Address" })}
                      htmlFor="search_address"
                      labelClassName="my-1 mr-2"
                    />
                    <input
                      id="adress"
                      type="text"
                      className="form-control mr-sm-3"
                      onChange={(e) => {
                        this.setState({ addressFilter: e.target.value });
                      }}
                      disabled={disableFormInput}
                      value={this.state.addressFilter}
                    />
                    <MenuButton
                      onClick={() => this.resetSearchFields()}
                      hover={
                        this.areResultsFiltered() &&
                        !disableFormInput && (
                          <FormattedMessage id="Remove.Filter" />
                        )
                      }
                      variant={
                        this.areResultsFiltered()
                          ? "warning"
                          : "outline-secondary"
                      }
                      icon="filter"
                      disabled={!this.areResultsFiltered() || disableFormInput}
                    />
                    {user.role === Roles.ADMIN && (
                      <button
                        className="btn btn-info ml-auto"
                        onClick={(e) => this.openModal()}
                      >
                        <FormattedMessage id="Add.Client" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <table className="table tablee4mad">
          {clients.length > 0 && (
            <thead>
              <tr>
                <th scope="col">
                  <FormattedMessage id="Name" /> <FormattedMessage id="And" />{" "}
                  <FormattedMessage id="First.Name" />
                </th>
                <th scope="col">
                  <FormattedMessage id="Type" />
                </th>
                <th scope="col">
                  <FormattedMessage id="Client.Label" />
                </th>
                <th scope="col">
                  <FormattedMessage id="Address" />
                </th>
                <th scope="col">
                  <FormattedMessage id="Nbr.Patients" />
                </th>
                <th scope="col">
                  <FormattedMessage id="Mercurial.Linked" />
                </th>
                {user.role === Roles.ADMIN && crmEnabled && (
                  <th scope="col" className="text-center">
                    <FormattedMessage
                      id="API.CRM.App.Link"
                      values={{ crmSoftware: crmSoftware }}
                    />
                  </th>
                )}
                {!this.props.limit && user.role === Roles.ADMIN && (
                  <th scope="col" className="text-center">
                    <FormattedMessage id="Actions" />
                  </th>
                )}
              </tr>
            </thead>
          )}
          <tbody>{clientsNode}</tbody>
        </table>

        {!this.props.limit && this.paginator.render()}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.clients,
    user: state.user,
    prescriberTypes: state.prescriberTypes,
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetClient: (clientId, successCallback) =>
      dispatch(getClient(clientId, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Prescribers));
