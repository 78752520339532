import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import DeskCategoriesRowAdmin from "./DeskCategoriesRowAdmin";

class DeskCategoriesTableAdmin extends React.Component {
  render() {
    let deskCategoryRowNode = this.props.deskCategories.map((deskCategory) => {
      return (
        <DeskCategoriesRowAdmin
          key={deskCategory._id}
          deskCategory={deskCategory}
          displaysAssociationModal={(deskCategory, target) =>
            this.props.displaysAssociationModal(deskCategory, target)
          }
          displaysProductModal={(deskCategory, target) =>
            this.props.displaysProductModal(deskCategory, target)
          }
          openEditModal={(deskCategory) =>
            this.props.openEditModal(deskCategory)
          }
          openConfModal={(title, content, successCallback) =>
            this.props.openConfModal(title, content, successCallback)
          }
        />
      );
    });
    return (
      <React.Fragment>
        <table className="table tablee4mad">
          <thead>
            <tr>
              <th>
                <FormattedMessage id="Name" />
              </th>
              <th className="text-center">
                <FormattedMessage id="Associations" />
              </th>
              <th className="text-center">
                <FormattedMessage id="Types.Products" />
              </th>
              <th className="text-center col-2">
                <FormattedMessage id="Actions" />
              </th>
            </tr>
          </thead>
          <tbody>{deskCategoryRowNode}</tbody>
        </table>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeskCategoriesTableAdmin);
