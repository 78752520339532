import React from "react";
import { connect } from "react-redux";
import DateUtil from "../../util/DateUtil";
import PDFUtil from "../../util/PDFUtil";
import { injectIntl } from "react-intl";
import APIUrl from "../../APIUrl";
import { FormattedMessage } from "react-intl";
import Roles from "../../enums/Roles";
import ActionMenu from "../sub/ActionMenu";

class InterventionRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,

      disabled: null,

      collapsed: false,
      displayPDF: false,
      disableExport: false,
    };
  }

  close() {
    this.props.closeModal();
  }

  closeModal() {
    this.setState({ modal: null });
  }

  togglePDF(pdfElemId, pdfFileName) {
    if (this.state.disableExport) return;

    if (process.env.REACT_APP_PDF_OVERVIEW === "true") {
      // Possibility n°1 (dev env) : display preview of PDF (without header, footer, pages etc.)
      this.setState({ displayPDF: !this.state.displayPDF });
    } else {
      // Possibility n°2 (prod env) : directly save to PDF
      this.setState({ disableExport: true });
      let successCallback = () => this.setState({ disableExport: false });
      PDFUtil.toPDF(
        this.props.generalSettings,
        this.props.company,
        pdfElemId,
        pdfFileName,
        "/css/pdf.css",
        successCallback,
      );
    }
  }

  render() {
    const { intervention, user } = this.props;
    const interventionDate =
      intervention.signature_date === undefined
        ? DateUtil.toDate(intervention.creation_date)
        : DateUtil.toDate(intervention.signature_date);

    const contactName =
      user.role !== Roles.CLIENT
        ? intervention.technician_name
        : intervention.patient_name;

    let menuItems = [];

    menuItems.push(
      {
        icon: "file-pdf",
        action: () =>
          window.open(
            encodeURI(
              APIUrl.getInterventionsPDF +
                intervention.patient_id +
                "/" +
                intervention._id +
                "?token=" +
                APIUrl.jwtToken,
            ),
            "_system",
            "transitionstyle=fliphorizontal",
          ),
        text: <FormattedMessage id="Display.PDF" />,
        disabled: this.state.disableExport,
      },
      user.role === Roles.ADMIN && {
        icon: "trash",
        text: <FormattedMessage id="Delete" />,
        action: () => this.props.deleteIntervention(intervention),
        disabled: this.state.disableExport,
      },
    );

    const menuAction = <ActionMenu items={menuItems} />;

    return (
      <React.Fragment>
        <tr key={intervention._id}>
          <td>{interventionDate}</td>
          <td>
            <FormattedMessage
              id={"Intervention.Type." + intervention.intervention_type}
            />
          </td>
          {user.role !== Roles.CLIENT && !this.props.equipment && (
            <td>{intervention.patient_name}</td>
          )}
          <td>{contactName}</td>
          {!this.props.limit && <td className="text-center">{menuAction}</td>}
        </tr>

        {/* {this.state.modal} */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    generalSettings: state.generalSettings,
    company: state.company,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(InterventionRow));
