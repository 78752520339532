import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";

import RequestTypes from "../../enums/RequestTypes";
import EstimateStatus from "../../enums/EstimateStatus";
import Roles from "../../enums/Roles";

import Paginator from "../sub/Paginator";
import CustomLabel from "../sub/CustomLabel";

import DateUtil from "../../util/DateUtil";
import Util from "../../util/Util";
import StringUtil from "../../util/StringUtil";

import RequestRow from "../requests/RequestRow";
import RequestModal from "../requests/RequestModal";
import EstimateModalAdmin from "./EstimateModalAdmin";
import { Card } from "react-bootstrap";
import MenuButton from "../sub/bootstrap/MenuButton";

class Estimates extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      clientFilter: "",
      dateFilter: "",
      statusFilter: "",
    };

    this.paginator = new Paginator(this);
  }

  openRequestModalAdmin(request) {
    this.setState({
      modal: (
        <RequestModal
          isOpen={true}
          close={() => this.closeModal()}
          requestType={request.request_type}
          requestId={request._id}
        />
      ),
    });
  }

  openRequestModal() {
    this.setState({
      modal: (
        <RequestModal
          isOpen={true}
          requestType={RequestTypes.NEW_MATERIAL}
          close={() => this.closeModal()}
        />
      ),
    });
  }

  openRequestDetailModal(request) {
    this.setState({
      modal: (
        <RequestModal
          isOpen={true}
          close={() => this.closeModal()}
          requestType={request.request_type}
          requestId={request._id}
        />
      ),
    });
  }

  openEstimateModalAdmin() {
    this.setState({
      modal: (
        <EstimateModalAdmin
          isOpen={true}
          close={() => this.closeModal()}
          requestType={RequestTypes.NEW_MATERIAL}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  sortByDateDesc(order1, order2) {
    let result = 0;
    if (order1.creation_date > order2.creation_date) result--;
    if (order1.creation_date < order2.creation_date) result++;
    return result;
  }

  countMaterial(requests) {
    let tot = 0;
    for (let request of requests)
      if (request.request_type === RequestTypes.NEW_MATERIAL) tot++;
    return tot;
  }

  setDateFilter = (date) => {
    this.setState({
      dateFilter: new Date(
        new Date(date).getFullYear(),
        new Date(date).getMonth(),
        new Date(date).getDate(),
        0,
        0,
        0,
        0,
      ),
    });
  };

  areResultsFiltered = () => {
    let result = false;
    if (
      !Util.emptyString(this.state.clientFilter) ||
      !Util.emptyString(this.state.dateFilter) ||
      !Util.emptyString(this.state.statusFilter)
    ) {
      result = true;
    }
    return result;
  };

  resetSearchFields() {
    this.setState({ clientFilter: "", dateFilter: "", statusFilter: "" });
  }

  render() {
    let isClient = this.props.user.role === Roles.CLIENT;
    let materialNbr = this.countMaterial(this.props.requests);

    this.paginator.init();

    let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

    let i = 0;
    let requestRowNode = this.props.requests.map((request) => {
      if (request.request_type !== RequestTypes.NEW_MATERIAL) return null;

      if (this.state.clientFilter && this.state.clientFilter !== "") {
        if (request.client_id !== this.state.clientFilter) return null;
      }

      if (this.state.dateFilter && this.state.dateFilter !== "") {
        if (
          DateUtil.toDate(request.creation_date) !==
          DateUtil.toDate(this.state.dateFilter)
        )
          return null;
      }

      if (this.state.statusFilter && this.state.statusFilter !== "") {
        if (parseInt(request.status) !== parseInt(this.state.statusFilter))
          return null;
      }

      if (this.props.limit && ++i > this.props.limit) return null;

      if (!this.paginator.keep()) return null;

      return (
        <RequestRow
          includeType={false}
          key={request._id}
          request={request}
          clientName={request.client_name}
          openRequestDetailModal={(request) =>
            this.openRequestDetailModal(request)
          }
          openRequestModalAdmin={(request) =>
            this.openRequestModalAdmin(request)
          }
          limit={this.props.limit && true}
        />
      );
    });

    let clientNode = this.props.clients.map((client) => {
      return (
        <option key={client._id} value={client._id}>
          {client.name &&
            client.name.toUpperCase() +
              " " +
              StringUtil.ucFirst(client.first_name)}
        </option>
      );
    });

    let statusFilterNode = Object.values(EstimateStatus).map((status) => {
      return (
        <option key={status} value={status}>
          {this.props.intl.formatMessage({ id: "Estimate.Status." + status })}
        </option>
      );
    });

    return (
      <React.Fragment>
        {materialNbr === 0 && (
          <React.Fragment>
            <div className="row search-filters">
              <div className="col-12">
                <Card>
                  <Card.Body>
                    <div className="form-inline">
                      <div className="alert alert-info w-100">
                        <FormattedMessage id="Empty.Requests" />
                      </div>

                      <button
                        className="btn btn-info ml-auto"
                        onClick={(e) =>
                          isClient
                            ? this.openRequestModal()
                            : this.openEstimateModalAdmin()
                        }
                      >
                        <FormattedMessage id="Create.Request" />
                      </button>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </React.Fragment>
        )}
        {materialNbr > 0 && (
          <React.Fragment>
            {!this.props.limit && (
              <div className="row search-filters">
                <div className="col-12">
                  <Card>
                    <Card.Body>
                      <div className="form-inline">
                        {!isClient && (
                          <>
                            <CustomLabel
                              label={this.props.intl.formatMessage({
                                id: "Client",
                              })}
                              htmlFor="search_client"
                              labelClassName="my-1 mr-2"
                            />
                            <select
                              id="search_client"
                              className="form-control mr-sm-3"
                              onChange={(e) => {
                                this.setState({ clientFilter: e.target.value });
                              }}
                              value={this.state.clientFilter}
                              disabled={disableFormInput}
                            >
                              <option value="">
                                {this.props.intl.formatMessage({ id: "All" })}
                              </option>
                              {clientNode}
                            </select>
                          </>
                        )}
                        <CustomLabel
                          label={this.props.intl.formatMessage({ id: "Date" })}
                          htmlFor="search_date"
                          labelClassName="my-1 mr-2"
                        />
                        <input
                          id="search_date"
                          className="form-control mr-sm-3"
                          type="date"
                          onChange={(e) => {
                            this.setState({ dateFilter: e.target.value });
                          }}
                          value={this.state.dateFilter}
                          disabled={disableFormInput}
                        />

                        <CustomLabel
                          label={this.props.intl.formatMessage({
                            id: "Status",
                          })}
                          htmlFor="search_status"
                          labelClassName="my-1 mr-2"
                        />
                        <select
                          id="search_status"
                          className="form-control mr-sm-3"
                          onChange={(e) => {
                            this.setState({ statusFilter: e.target.value });
                          }}
                          value={this.state.statusFilter}
                          disabled={disableFormInput}
                        >
                          <option value="">
                            {this.props.intl.formatMessage({ id: "All" })}
                          </option>
                          {statusFilterNode}
                        </select>

                        <MenuButton
                          onClick={() => this.resetSearchFields()}
                          hover={
                            this.areResultsFiltered() &&
                            !disableFormInput && (
                              <FormattedMessage id="Remove.Filter" />
                            )
                          }
                          variant={
                            this.areResultsFiltered()
                              ? "warning"
                              : "outline-secondary"
                          }
                          icon="filter"
                          disabled={
                            !this.areResultsFiltered() || disableFormInput
                          }
                        />
                        <button
                          className="btn btn-info ml-auto"
                          onClick={(e) =>
                            isClient
                              ? this.openRequestModal()
                              : this.openEstimateModalAdmin()
                          }
                        >
                          <FormattedMessage id="Create.Request" />
                        </button>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            )}

            <table className="table tablee4mad">
              <thead>
                <tr className={isClient ? "d-flex" : ""}>
                  {!isClient && (
                    <th scope="col">
                      <FormattedMessage id="Client" />
                    </th>
                  )}
                  <th scope="col" className={isClient ? "col" : ""}>
                    <FormattedMessage id="Estimate.Request" />
                  </th>
                  <th scope="col" className="col col-2">
                    <FormattedMessage id="Date" />
                  </th>
                  <th scope="col" className="col col-2">
                    <FormattedMessage id="Status" />
                  </th>
                  {!this.props.limit && (
                    <th scope="col" className="col-1 text-center">
                      <FormattedMessage id="Actions" />
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>{requestRowNode}</tbody>
            </table>

            {!this.props.limit && this.paginator.render()}
          </React.Fragment>
        )}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requests: state.requests,
    clients: state.clients,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Estimates));
