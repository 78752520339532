import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import AddPrescriberModal from "./AddPrescriberModal";
import ApiConfiguration from "../api/must/ApiConfiguration";
import { Modal, Tabs, Tab } from "react-bootstrap";
import Patients from "../patients/Patients";
import Roles from "../../enums/Roles";
import Util from "../../util/Util";
import { updPatientAdmin } from "../../actions/patients/patients";

class PrescriberModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  addClient(patient) {
    if (!patient) return;

    const addClientToPatient = (fieldNameIds, fieldNameLead) => {
      let clonedPatient = Util.shallowClone(patient);

      if (!clonedPatient[fieldNameIds]) clonedPatient[fieldNameIds] = [];

      clonedPatient[fieldNameIds].push(this.props.client._id);

      if (clonedPatient[fieldNameIds].length === 1) {
        clonedPatient[fieldNameLead] = this.state[fieldNameLead];
      }

      return clonedPatient;
    };

    const successCallback = () => this.setState({ modal: null });

    let clonedPatient = {};
    let clientsData = {};

    if (this.props.client.client_type === "helper") {
      clonedPatient = addClientToPatient("helpers_ids", "helper_lead");
      clientsData = {
        _id: patient._id,
        helpers_ids: clonedPatient.helpers_ids,
      };
    } else if (this.props.client.client_type === "prescriber") {
      clonedPatient = addClientToPatient("prescribers_ids", "prescriber_lead");
      clientsData = {
        _id: patient._id,
        prescribers_ids: clonedPatient.prescribers_ids,
      };
    }

    this.props.onUpdPatientAdmin(clientsData, successCallback);
  }

  openAssignModal() {
    this.setState({
      modal: (
        <Modal
          show={true}
          onHide={() => this.closeModal()}
          backdrop={"static"}
          size={"xl"}
        >
          <Modal.Body>
            <div className="mb-5">
              <button
                type="button"
                className="close left"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.closeModal()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <Patients
              clientId={this.props.client._id}
              isAssignModal={true}
              addClient={(patient) => this.addClient(patient)}
              limitInfoAssociatedPatients={true}
            />
          </Modal.Body>
        </Modal>
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  close() {
    this.props.close();
  }

  confirm() {
    this.props.successCallback();
    this.close();
  }

  stopEvent(e) {
    e.preventDefault();
    e.stopPropagation();
  }
  render() {
    let client = this.props.client;
    let target = this.props.target || "clientInfo";
    let aClassName = this.props.client ? "" : "disabled";

    // https://stackoverflow.com/questions/37308719/react-component-wait-for-required-props-to-render
    // https://zaiste.net/posts/javascript-destructuring-assignment-default-values/
    const { enabled: crmEnabled = false, software: crmSoftware = null } = this
      .props.company.crm
      ? this.props.company.crm
      : {};
    return (
      <div>
        <Modal
          show={true}
          onHide={() => this.close()}
          backdrop="static"
          keyboard={false}
          size="xl"
        >
          <Modal.Body>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => this.close()}
            >
              <span aria-hidden="true">&times;</span>
            </button>

            <Tabs defaultActiveKey={target} id="clientTabs">
              <Tab
                eventKey="clientInfo"
                title={this.props.intl.formatMessage({
                  id: "Informations",
                })}
              >
                <AddPrescriberModal
                  isOpen={true}
                  prescriberId={client ? client._id : null}
                  close={() => this.close()}
                />
              </Tab>
              {client && (
                <Tab
                  eventKey="clientPatients"
                  title={this.props.intl.formatMessage({
                    id: "Patients.Monitoring",
                  })}
                >
                  <Patients
                    clientId={client._id}
                    clientType={client.client_type}
                    openAssignModal={() => this.openAssignModal()}
                    limitInfoAssociatedPatients={true}
                  />
                </Tab>
              )}
              {
                // Check if account is linked to an external CRM and display associated tab to PSDM admin only
                crmEnabled &&
                  this.props.user.role === Roles.ADMIN &&
                  this.props.client && (
                    <Tab
                      eventKey="apiConfiguration"
                      title={this.props.intl.formatMessage(
                        { id: "API.CRM.App.Link" },
                        { crmSoftware: crmSoftware },
                      )}
                      disabled={aClassName}
                    >
                      <ApiConfiguration client={this.props.client} />
                    </Tab>
                  )
              }
            </Tabs>
          </Modal.Body>
        </Modal>

        {this.state.modal}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    company: state.company,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdPatientAdmin: (patient, successCallback) =>
      dispatch(updPatientAdmin(patient, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(PrescriberModal));
