import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Modal, Alert } from "react-bootstrap";
import Util from "../../util/Util";
import FileDropZone from "../sub/FileDropZone";
import FileUtil from "../../util/FileUtil";

class DocumentModal extends React.Component {
  constructor(props) {
    super(props);

    const { parent, document, parentId } = this.props;
    const parentAppScope = parent?.app_scope;
    const parentUserScope = parent?.user_scope;
    const documentCategory = document?.category;

    const defaultAppScope = {
      e4bizz: parentAppScope?.e4bizz ?? false,
      e4coll: parentAppScope?.e4coll ?? false,
      e4mad: parentAppScope?.e4mad ?? true,
      e4vhp: parentAppScope?.e4vhp ?? false,
    };

    const defaultUserScope = {
      prescriber: parentUserScope?.prescriber ?? false,
      patient: parentUserScope?.patient ?? true,
    };

    this.state = {
      categoryId: documentCategory?._id || parentId || "",
      appScope: document?.app_scope || defaultAppScope,
      userScope: document?.user_scope || defaultUserScope,
      file: null,
      fileError: "",
      disabled: false,
    };
  }

  disabled() {
    return (
      Util.emptyString(this.state.categoryId) ||
      !Util.emptyString(this.state.fileError) ||
      (!this.state.appScope.e4bizz &&
        !this.state.appScope.e4coll &&
        !this.state.appScope.e4mad &&
        !this.state.appScope.e4vhp) ||
      !this.state.file ||
      this.state.disabled
    );
  }

  onDropFile(file) {
    if (file.size > process.env.REACT_APP_UPLOAD_MAXFILESIZE)
      return this.setState({
        fileError: (
          <FormattedMessage
            id="File.Oversized"
            values={{
              value: FileUtil.bytesToSize(
                process.env.REACT_APP_UPLOAD_MAXFILESIZE,
              ),
            }}
          />
        ),
      });

    this.setState({ fileError: "", file: file });
  }

  onFileSubmit(document) {
    let formData = new FormData();
    formData.append(this.state.file.name, this.state.file);
    this.props.addDocumentFile(document._id, formData, () => {
      this.props.syncData();
      this.props.close();
    });
  }

  onSubmit() {
    if (this.disabled()) return;

    this.setState({ disabled: true });

    const fileExtension = this.state.file.name.split(".");

    const document = {
      date: Date.now(),
      category_id: this.state.categoryId,
      app_scope: this.state.appScope,
      user_scope: this.state.userScope,
      file_name: this.state.file.name,
      file_extension: fileExtension[fileExtension.length - 1],
      file_size: this.state.file.size,
    };

    if (!this.props.document) {
      this.props.addDocument(document, (document) =>
        this.onFileSubmit(document),
      );
    } else {
      document._id = this.props.document._id;
      document.date = this.props.document.date;
      this.props.updateDocument(document, () => this.onFileSubmit(document));
    }
  }

  render() {
    const { document } = this.props;

    return (
      <Modal
        show={true}
        onHide={() => this.props.close()}
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {document ? (
              <FormattedMessage id={"Replace.Document"} />
            ) : (
              <FormattedMessage id={"Add.Document"} />
            )}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {document && (
            <Alert variant="warning">
              <FormattedMessage
                id={"Replace.Document.Warning"}
                values={{ file_name: <strong>"{document.file_name}"</strong> }}
              />
            </Alert>
          )}

          {!document && (
            <Alert variant="info">
              <FormattedMessage id={"Destination.Folder.Message"} />{" "}
              <strong>"{this.props.parent.name}"</strong>.
            </Alert>
          )}

          <div>
            <FileDropZone
              onDropFile={(file) => this.onDropFile(file)}
              acceptedExtensions={[
                "pdf",
                "doc",
                "docx",
                "txt",
                "jpg",
                "jpeg",
                "png",
              ]}
              multiple={false}
            />
            <p className="text-danger">{this.state.fileError}</p>
          </div>

          <div className="row mt-5">
            <div className="col-12 col-lg-6 offset-lg-3">
              <button
                className="btn btn-info btn-block"
                disabled={this.disabled()}
                onClick={() => this.onSubmit()}
              >
                <FormattedMessage id={document ? "Replace" : "Add"} />
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(DocumentModal));
