import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import { logout } from "../../../actions/authentication/authentication";
import { updateLang } from "../../../actions/user/user";
import ActionMenu from "../../sub/ActionMenu";
import Roles from "../../../enums/Roles";
import { Alert, Modal, Button } from "react-bootstrap";
import { deleteCompanyNotifAdmin } from "../../../actions/company/company";
import MenuButton from "../../../components/sub/bootstrap/MenuButton";

class DropdownMenuProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notifrender: null,
      notificationModal: null,
      modal: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.company !== prevProps.company) {
      if (
        this.props.company &&
        this.props.company.notif &&
        (this.props.user.role === Roles.ADMIN ||
          this.props.user.role === Roles.SALES_REP)
      ) {
        this.setState({
          notificationModal: (
            <Modal show={true} backdrop="static" keyboard={false} size="lg">
              <Modal.Header>
                <Modal.Title>
                  <FormattedMessage id="Warning" />s
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Alert variant="warning">
                  <FormattedMessage id="Warning.Detect.Notice" />
                </Alert>
                <table className="table tablee4mad">
                  <thead>
                    <tr className="d-flex align-items-center">
                      <th scope="col" className="col">
                        <FormattedMessage id="Module" />
                      </th>
                      <th scope="col" className="col col-8">
                        <FormattedMessage id="Error.Detail" />
                      </th>
                      <th scope="col" className="col col-2 text-right">
                        <FormattedMessage id="Actions" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.company.notif.map((notif, index) => {
                      return (
                        <tr key={index} className="d-flex align-items-center">
                          <td className="col">
                            {notif.type === "crm"
                              ? this.props.company.crm.software
                              : notif.type}
                          </td>
                          <td className="col col-8 text-break">{notif.msg}</td>
                          <td className="col col-2 text-right">
                            <MenuButton
                              icon="trash"
                              onClick={() =>
                                this.props.onDeleteCompanyNotifAdmin(
                                  { index: index },
                                  () => this.displayNotificationModal(false),
                                )
                              }
                              variant="danger"
                              hover={<FormattedMessage id="Delete" />}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => this.displayNotificationModal(false)}
                >
                  <FormattedMessage id="Close" />
                </Button>
              </Modal.Footer>
            </Modal>
          ),
        });
      }
    }
  }

  logout(e) {
    e.preventDefault();
    e.stopPropagation();

    let that = this;
    function onLogoutSuccess() {
      that.props.history.push("/");
    }

    // Call logout and redirect on success
    this.props.onLogout(onLogoutSuccess);
  }

  updateLang(lang) {
    this.props.onUpdateLang(lang);
  }

  displayNotificationModal(dialogState) {
    this.setState({
      //displayModal: !this.state.displayModal,
      modal: dialogState ? this.state.notificationModal : null,
    });
  }

  render() {
    var lang = this.props.lang;

    if (!lang) return;

    // Available langs to chose from
    // var availableLangs = ["en", "fr"];

    // var otherLangsNode = availableLangs.map((l) => {
    //     // Lang is the one currently selected. Return
    //     var color = null;
    //     if(l === lang) color = "langselected";
    //
    //     // Add option to pick this lang
    //     return (
    //         <span key={l} className={color + " ml-2 lang"} onClick={(e) => this.updateLang(l)}><FormattedMessage id={"Lang." + l} /></span>
    //     );
    // });

    let menuItems = [];

    if (
      this.props.company.notif?.length &&
      (this.props.user.role === Roles.ADMIN ||
        this.props.user.role === Roles.SALES_REP)
    ) {
      menuItems.push({
        icon: "exclamation-triangle",
        action: () => this.displayNotificationModal(true),
        text: (
          <>
            <FormattedMessage id="Warning" />
            (s)
          </>
        ),
        notification: this.props.company.notif.length,
        variant: "danger",
      });
    }

    menuItems.push({
      icon: "pen-to-square",
      href: "#/home/account",
      text: <FormattedMessage id="My.Account" />,
    });

    menuItems.push({
      icon: "circle-info",
      href: "#/home/changelog",
      disabled: this.props.user.role === Roles.CLIENT && true,
      text: "v" + process.env.REACT_APP_VERSION,
    });

    menuItems.push({
      icon: "power-off",
      action: (e) => this.logout(e),
      text: <FormattedMessage id="Disconnect" />,
    });

    const menuAction = (
      <ActionMenu
        items={menuItems}
        icon={
          this.props.company.notif?.length &&
          (this.props.user.role === Roles.ADMIN ||
            this.props.user.role === Roles.SALES_REP)
            ? "exclamation-triangle"
            : "user"
        }
        variant={
          this.props.company.notif?.length &&
          (this.props.user.role === Roles.ADMIN ||
            this.props.user.role === Roles.SALES_REP) &&
          "danger"
        }
        size="xl"
        header={this.props.user.first_name + " " + this.props.user.name}
      />
    );

    return (
      <React.Fragment>
        {menuAction}
        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.i18n.lang,
    user: state.user,
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: (successCallback) => dispatch(logout(successCallback)),
    onUpdateLang: (lang) => dispatch(updateLang(lang)),
    onDeleteCompanyNotifAdmin: (data, successCallback) =>
      dispatch(deleteCompanyNotifAdmin(data, successCallback)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DropdownMenuProfile),
);
