import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { updateGeneralSettings } from "../../../../actions/settings/generalSettings/generalSettings";
import { Col, Form, Row, Tabs, Tab } from "react-bootstrap";
import ConfirmationModal from "../../../sub/modals/ConfirmationModal";
import PopoverHelper from "../../../sub/bootstrap/PopoverHelper";

class GeneralSettingsAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: null,
    };
  }

  onCheck(key, value) {
    const successCallback = () => this.update(key, value);

    if (!value) {
      const title = <FormattedMessage id="Warning" />;
      const content = <FormattedMessage id="Securised.Documents.Message" />;
      return this.openConfModal(title, content, successCallback);
    }

    successCallback();
  }

  closeModal() {
    this.setState({ modal: null });
  }

  openConfModal(title, content, successCallback) {
    this.setState({
      modal: (
        <ConfirmationModal
          title={title}
          content={content}
          context="danger"
          successCallback={successCallback}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  update(field, value) {
    const data = {
      generalSettingsId: this.props.generalSettings._id,
      updatedField: field,
      updatedValue: value,
    };

    this.props.onUpdateGeneralSettings(data);
  }

  render() {
    if (this.props.generalSettings.length === 0) return null;

    return (
      <>
        <Tabs defaultActiveKey="emails" id="securityTabs">
          <Tab
            eventKey="emails"
            title={this.props.intl.formatMessage({ id: "Emails" })}
          >
            <Row>
              <Col>
                <Form.Check
                  inline
                  label={
                    <>
                      <span
                        className={
                          this.props.generalSettings.useEmailSecurisedDocuments
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        <FormattedMessage id="Securised.Documents" />
                      </span>
                      <PopoverHelper>
                        <FormattedMessage id="Securised.Documents.Message" />
                      </PopoverHelper>
                    </>
                  }
                  type={"checkbox"}
                  checked={
                    this.props.generalSettings.useEmailSecurisedDocuments
                  }
                  onChange={() =>
                    this.onCheck(
                      "useEmailSecurisedDocuments",
                      !this.props.generalSettings.useEmailSecurisedDocuments,
                    )
                  }
                />
              </Col>
            </Row>
          </Tab>
        </Tabs>
        {this.state.modal}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    generalSettings: state.generalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateGeneralSettings: (data) => dispatch(updateGeneralSettings(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(GeneralSettingsAdmin));
