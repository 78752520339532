import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Tab, Tabs } from "react-bootstrap";

import OrdersSettingsAdmin from "./ordersSettings/OrdersSettingsAdmin";
import GeneralSettingsAdmin from "./GeneralSettingsAdmin";
import Audit from "./audit/Audit";
import PrescriberTypes from "./prescriberType/PrescriberTypes";
import PromotionalOperations from "./promotionalOperations/PromotionalOperations";
import Collaborators from "./collaborators/Collaborators";
import AuditDesk from "./audit/AuditDesk";
import Security from "./security/Security";

class MenuSettingsAdmin extends React.Component {
  render() {
    const {
      intl,
      ordersSettingsAdmin,
      generalSettings,
      collaborators,
      promoOperations,
      user,
    } = this.props;

    if (Object.keys(ordersSettingsAdmin).length === 0) return null;
    if (Object.keys(generalSettings).length === 0) return null;

    return (
      <React.Fragment>
        <Tabs
          defaultActiveKey="settings-general"
          id="settingsTabs"
          mountOnEnter
        >
          <Tab
            eventKey="settings-general"
            title={intl.formatMessage({ id: "General" })}
          >
            <GeneralSettingsAdmin generalSettings={generalSettings} />
          </Tab>

          <Tab
            eventKey="settings-collaborators"
            title={intl.formatMessage({ id: "Collaborators" })}
          >
            <Collaborators collaborators={collaborators} />
          </Tab>

          <Tab
            eventKey="settings-client"
            title={intl.formatMessage({ id: "Client.Type" })}
          >
            <PrescriberTypes />
          </Tab>

          <Tab
            eventKey="settings-module-store"
            title={intl.formatMessage({ id: "Mercurials.Management" })}
          >
            <Tabs defaultActiveKey="module-store-cost-budget" id="moduleStore">
              <Tab
                eventKey="module-store-cost-budget"
                title={intl.formatMessage({ id: "Cost.Budget" })}
              >
                <OrdersSettingsAdmin ordersSettings={ordersSettingsAdmin} />
              </Tab>
              <Tab
                eventKey="module-store-promotional-operation"
                title={intl.formatMessage({ id: "Promotional.Operations" })}
              >
                <PromotionalOperations promoOperations={promoOperations} />
              </Tab>
            </Tabs>
          </Tab>

          <Tab
            eventKey="settings-module-home"
            title={intl.formatMessage({ id: "Audit.Settings" })}
          >
            <Audit />
          </Tab>

          {user.access_module_desk && (
            <Tab
              eventKey="settings-module-desk"
              title={intl.formatMessage({ id: "Module.Mag" })}
            >
              <AuditDesk />
            </Tab>
          )}

          <Tab
            eventKey="settings-security"
            title={intl.formatMessage({ id: "Security" })}
          >
            <Security />
          </Tab>
        </Tabs>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    ordersSettingsAdmin: state.ordersSettingsAdmin,
    generalSettings: state.generalSettings,
    promoOperations: state.promoOperations,
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(MenuSettingsAdmin));
