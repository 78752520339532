import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import Icon from "../Icon.js";
class NotificationModal extends React.Component {
  close() {
    this.props.closeModal();
  }

  confirm() {
    if (this.props.successCallback) this.props.successCallback();
    this.close();
  }

  render() {
    const { size, title, content, buttonLabel } = this.props;
    let context = this.props.context || "info";

    return (
      <Modal
        show={true}
        onHide={() => this.close()}
        backdrop="static"
        keyboard={false}
        size="lg"
        dialogClassName={"border border-" + context + " " + size}
      >
        <Modal.Header closeButton>
          <Modal.Title className={"text-" + context}>
            <Icon icon="info-circle" size="2xl" className="mr-3" />
            {title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>{content}</Modal.Body>

        <Modal.Footer>
          <Button
            variant={context + " text-white"}
            onClick={() => this.confirm()}
          >
            {buttonLabel ? buttonLabel : <FormattedMessage id="Ok" />}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default NotificationModal;
