import React from "react";
import { connect } from "react-redux";
import MercurialFileModal from "./MercurialFileModal";
import MercurialColumnsModal from "./MercurialColumnsModal";
import MercurialImgsModal from "./MercurialImgsModal";
import MercurialUpdModal from "./MercurialUpdModal";
import {
  addMercurial,
  addProductsImgs,
  addFamilyImgs,
  updateMercurial,
} from "../../actions/mercurials/mercurials";
import MercurialRow from "./MercurialRow";
import ExcelUtil from "../../util/ExcelUtil";
import ConfirmationModal from "../sub/modals/ConfirmationModal";
import ErrorModal from "../sub/modals/ErrorModal";
import MercurialStatus from "../../enums/MercurialStatus";
import { updateMercurialStatus } from "../../actions/mercurials/mercurials";
import { deleteMercurial } from "../../actions/mercurials/mercurials";
import { FormattedMessage, injectIntl } from "react-intl";
import Paginator from "../sub/Paginator";
import MercurialArchiveModal from "./MercurialArchiveModal";
import CustomLabel from "../sub/CustomLabel";
import MercurialsSyncArticlesModal from "./MercurialsSyncArticlesModal";
import DateUtil from "../../util/DateUtil";
import Util from "../../util/Util";
import { findArticlesMust } from "../../actions/apiMust/apiMust";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import Icon from "../sub/Icon.js";
import MenuButton from "../sub/bootstrap/MenuButton";

class Mercurials extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      // Import data
      mercurialName: null,
      fileName: null,
      fileData: null,
      startDate: null,
      endDate: null,
      columns: null,
      fileError: null,
      // Filters
      nameFilter: "",
      startDateFilter: "",
      endDateFilter: "",
      mercurialsFilter: 0,
      disabledInactiveMercurial: false,
      mode: "create",
    };

    this.paginator = new Paginator(this);
  }

  // First modal when importing a new mercurial
  openMercurialImportModal(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      mode: "create",
      modal: (
        <MercurialFileModal
          closeModal={() => this.abortAndCloseModal()}
          onComplete={(mercurialName, fileData, startDate, endDate) =>
            this.onCompleteStep1(mercurialName, fileData, startDate, endDate)
          }
        />
      ),
    });
  }

  // Action to do after the first modal (go to step 2)
  onCompleteStep1(mercurialName, file, startDate, endDate) {
    // Parse the excel file and then move on to next modal
    ExcelUtil.parse(file, (fileData) => {
      // Save the passed params
      this.setState({
        mercurialName: mercurialName,
        fileName: file.name,
        fileData: fileData,
        startDate: startDate,
        endDate: endDate,
      });

      // Close current modal
      this.closeModal();

      // Open next step modal
      this.setState({
        modal: (
          <MercurialColumnsModal
            closeModal={() => this.closeModal()}
            fileData={fileData}
            onComplete={(columns) => this.onCompleteStep2(columns)}
          />
        ),
      });
    });
  }

  // Open an "error modal" if the simple checks above have failed
  openCorruptFileModal() {
    var errorModalTitle = <FormattedMessage id="Error" />;
    var errorModalContent = (
      <>
        <p className="text-justify">
          <FormattedMessage id="Mercurial.File.Missing.Data" />
        </p>
        <ul>
          <li>
            <FormattedMessage id="Line" />: {this.state.fileError.numRow}
          </li>
          <li>
            <FormattedMessage id="Mercurial.Number.Of.Expected.Columns" />:{" "}
            {this.state.fileError.nbrKeys}
          </li>
          <li>
            <FormattedMessage id="Mercurial.Number.Of.Found.Columns" />:{" "}
            {this.state.fileError.foundKeys}
          </li>
        </ul>
      </>
    );

    this.setState({
      mercurialName: null,
      fileName: null,
      fileData: null,
      startDate: null,
      endDate: null,
      columns: null,
      modal: (
        <ErrorModal
          isOpen={true}
          title={errorModalTitle}
          content={errorModalContent}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  // Action to do after the second modal (go to step 3 in "create" mode or simply update mercurial in "update" mode)
  onCompleteStep2(columns, mercurial) {
    this.setState({
      columns: columns,
    });

    let successCallback;

    if (this.state.mode === "update" && Util.typeOf(mercurial) === "Object") {
      successCallback = () => {
        // Close current modal
        this.closeModal();

        // Notification
        let errorMessage = this.props.intl.formatMessage({
          id: "Mercurial.Updated",
        });

        NotificationManager.success(errorMessage);
      };

      this.updateMercurial(
        mercurial,
        this.state.fileName,
        this.state.fileData,
        columns,
        mercurial.start_date,
        successCallback,
      );
    } else {
      successCallback = (mercurial) => {
        // Close current modal
        this.closeModal();
        this.openImgsModal(mercurial);
      };

      // Send the mercurial to the BE
      this.addMercurial(
        this.state.mercurialName,
        this.state.fileName,
        this.state.fileData,
        this.state.startDate,
        this.state.endDate,
        columns,
        successCallback,
      );
    }
  }

  /**
   * Send a new mercurial to the backend (create mode)
   *
   * @param {*} name
   * @param {*} fileName
   * @param {*} data
   * @param {*} startDate
   * @param {*} endDate
   * @param {*} columns
   * @param {*} callback
   */
  addMercurial(name, fileName, data, startDate, endDate, columns, callback) {
    // First, fix data with correct columns
    var products = this.fixProductsCols(data, columns);

    // Then, send data to the BE
    let mercurial = {
      name: name,
      fileName: fileName,
      startDate: startDate,
      endDate: endDate,
      products: products,
    };
    this.props.onaddMercurial(mercurial, callback);
  }
  /**
   * Send an updated mercurial to the backend (update mode)
   *
   * @param {*} mercurial
   * @param {String} fileName
   * @param {Array} data
   * @param {Date} date choose when the mercurial as added.
   * @param {String} columns name of correspondace column
   * @param {Function} callback
   */
  updateMercurial(mercurial, fileName, data, columns, startDate, callback) {
    // First, fix data with correct columns
    var products = this.fixProductsCols(data, columns);

    // Set date to a correct ISO FORMAT http://en.wikipedia.org/wiki/ISO_8601
    let nowDate = new Date().toISOString();
    if (startDate < nowDate) {
      startDate = nowDate;
    }

    // Send to BE
    this.props.onUpdateMercurial(
      {
        mercurialId: mercurial._id,
        fileName: fileName,
        products: products,
        startDate: startDate,
      },
      callback,
    );
  }

  // Perform checks on columns <-> data associations for products
  fixProductsCols(products, columns) {
    let newProducts = [];
    for (let p of products) {
      var newProduct = {};
      for (let col of Object.keys(columns)) newProduct[col] = p[columns[col]];
      newProducts.push(newProduct);
    }

    return newProducts;
  }

  // Confirmation modal displayed when user choose to delete a mercurial
  openConfModal(title, content, successCallback) {
    this.setState({
      modal: (
        <ConfirmationModal
          isOpen={true}
          title={title}
          size="xl"
          content={content}
          successCallback={successCallback}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  openModal(errorModalTitle, errorModalContent) {
    this.setState({
      modal: (
        <ErrorModal
          isOpen={true}
          title={errorModalTitle}
          content={errorModalContent}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  // In mercurial "create" mode, Step 3 is when we let the ability to the user to upload associated categories/products images
  openImgsModal(mercurial, mode) {
    this.setState({
      mode: mode === "update" ? mode : "create",
      modal: (
        <MercurialImgsModal
          closeModal={() => this.closeModal()}
          onComplete={(mercurialId, productImgs, familyImgs, useCustomImage) =>
            this.uploadImgs(
              mercurialId,
              productImgs,
              familyImgs,
              useCustomImage,
            )
          }
          mercurial={mercurial}
        />
      ),
    });
  }

  // Management of images uploading
  uploadImgs(mercurialId, productImgs, familyImgs, useCustomImage) {
    var sendProductImgs = (successCallback) => {
      if (!productImgs || productImgs.length === 0) return successCallback();

      let formData = new FormData();

      // Tell the backend if custom images must be stored and displayed instead existing images in database
      formData.append("useCustomImage", useCustomImage);

      for (let img of productImgs) formData.append(img.reference, img.file);

      this.props.onAddProductsImgs(mercurialId, formData, successCallback);
    };

    var sendFamilyImgs = (successCallback) => {
      if (!familyImgs || familyImgs.length === 0) return successCallback();

      let formData = new FormData();
      for (let img of familyImgs) formData.append(img.name, img);

      this.props.onAddFamilyImgs(mercurialId, formData, successCallback);
    };

    // Notification after image uploading in "create" mode (inform the user a new mercurial have been added)
    let errorMessage =
      this.state.mode === "update"
        ? this.props.intl.formatMessage({ id: "Mercurial.Updated" })
        : this.props.intl.formatMessage({ id: "Mercurial.Created" });
    let notification = () => {
      NotificationManager.success(errorMessage);
    };

    sendProductImgs(() => sendFamilyImgs(() => this.closeModal(notification)));
  }

  // Reset all previous import params in state when aborting import process
  abortAndCloseModal() {
    this.setState({
      mercurialName: null,
      fileName: null,
      fileData: null,
      startDate: null,
      endDate: null,
    });

    this.closeModal();
  }

  // Simple close modal function that can handle a possible callback if provided
  closeModal(callback) {
    this.setState({ modal: null }, callback);
  }

  // Open a modal to begin an "update" process for a mercurial (step 1 in "update" mode)
  openMercurialUpdModal(mercurial) {
    this.setState({
      mode: "update",
      modal: (
        <MercurialUpdModal
          mercurial={mercurial}
          closeModal={() => this.closeModal()}
          onComplete={(mercurial, file) => this.onUpd(mercurial, file)}
        />
      ),
    });
  }

  // Perform simple tests on file data in "update" mode before in-deep analysis (step 2 in "update" mode)
  onUpd(mercurial, file) {
    // Parse the excel file and then move on to next modal
    ExcelUtil.parse(file, (fileData) => {
      // Save the passed params
      this.setState({
        fileName: file.name,
        fileData: fileData,
      });

      // Open next step modal
      this.setState({
        modal: (
          <MercurialColumnsModal
            mode={this.state.mode}
            closeModal={() => this.abortAndCloseModal()}
            fileData={fileData}
            onComplete={(columns) => this.onCompleteStep2(columns, mercurial)}
          />
        ),
      });
    });
  }

  // CRM SYNC MODAL
  openSyncModal(products, crmSoftware, crmEnabled, mercurial) {
    this.setState({
      modal: (
        <MercurialsSyncArticlesModal
          openModal={(errorModalTitle, errorModalContent) =>
            this.openModal(errorModalTitle, errorModalContent)
          }
          products={products}
          crmEnabled={crmEnabled}
          crmSoftware={crmSoftware}
          mercurial={mercurial} //ajout de mercurial pour récupérer les produits
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  // Update mercurials status after an deletion or archive
  updateStatus(status, mercurial) {
    var data = {
      mercurialId: mercurial._id,
      updatedField: "status",
      updatedValue: status,
    };
    this.props.onUpdateMercurialStatus(data);
  }

  openEditModal(mercurial) {
    this.setState({
      modal: (
        <MercurialFileModal
          mercurial={mercurial}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  // Delete mercurial confirmation modal
  openLayersModal(mercurial) {
    // 'Delete order' modal setup
    var modalTitle = <FormattedMessage id="Confirm" />;
    var modalContent = (
      <React.Fragment>
        <FormattedMessage id="Mercurial.Remove.Confirmation" />
        <br />
        <br />
        <div className="card text-white bg-danger">
          <div className="card-header">
            <Icon icon="triangle-exclamation" className="mr-2 text-white" />
            <FormattedMessage id="Warning" />
          </div>
          <div className="card-body">
            <p className="card-text">
              <FormattedMessage id="Mercurials.Delete.Warning.Content" />
            </p>
          </div>
        </div>
      </React.Fragment>
    );

    var successCallback = (e) => {
      this.props.onDeleteMercurial({ mercurialId: mercurial._id });

      let errorMessage = this.props.intl.formatMessage({
        id: "Mercurial.Deleted",
      });
      NotificationManager.success(errorMessage);
    };

    if (
      mercurial.status === MercurialStatus.INACTIVE ||
      new Date(mercurial.end_date).setHours(0, 0, 0, 0) <
        new Date(new Date().setHours(0, 0, 0, 0))
    ) {
      this.openConfModal(modalTitle, modalContent, successCallback);
    } else {
      this.setState({
        modal: (
          <MercurialArchiveModal
            isOpen={true}
            openModalDelete={() =>
              this.openConfModal(modalTitle, modalContent, successCallback)
            }
            updateStatus={() => this.updateStatus(1, mercurial)}
            closeModal={() => this.closeModal()}
          />
        ),
      });
    }
  }

  setStartDateFilter = (date) => {
    this.setState({
      startDateFilter: new Date(
        new Date(date).getFullYear(),
        new Date(date).getMonth(),
        new Date(date).getDate(),
        0,
        0,
        0,
        0,
      ),
    });
  };

  setEndDateFilter = (date) => {
    this.setState({
      endDateFilter: new Date(
        new Date(date).getFullYear(),
        new Date(date).getMonth(),
        new Date(date).getDate(),
        23,
        59,
        59,
        999,
      ),
    });
  };

  areResultsFiltered = () => {
    if (
      !Util.emptyString(this.state.nameFilter) ||
      !Util.emptyString(this.state.startDateFilter) ||
      !Util.emptyString(this.state.endDateFilter) ||
      this.state.mercurialsFilter !== 2
    ) {
      return true;
    } else {
      return false;
    }
  };

  resetSearchFields() {
    this.setState({
      nameFilter: "",
      startDateFilter: "",
      endDateFilter: "",
      mercurialsFilter: 2,
    });
  }

  render() {
    // https://stackoverflow.com/questions/37308719/react-component-wait-for-required-props-to-render
    // https://zaiste.net/posts/javascript-destructuring-assignment-default-values/
    const { enabled: crmEnabled = false } = this.props.company.crm
      ? this.props.company.crm
      : {};

    if (this.props.mercurials.length === 0) {
      return (
        <React.Fragment>
          {this.state.modal}

          <div className="row search-filters">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  {/* <h5 className="card-title">Recherche</h5> */}
                  <div className="form-inline">
                    <div className="alert alert-info w-100">
                      <FormattedMessage id="Empty.Mercurials" />
                    </div>
                    <button
                      className="btn btn-info ml-auto"
                      onClick={(e) => this.openMercurialImportModal(e)}
                    >
                      <FormattedMessage id="Mercurials.Add.Some" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }

    this.paginator.init();

    let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

    var mercurialsNode = this.props.mercurials.map((mercurial) => {
      if (this.state.nameFilter && this.state.nameFilter !== "") {
        if (
          mercurial.name
            .toLowerCase()
            .indexOf(this.state.nameFilter.toLowerCase()) === -1
        )
          return null;
      }

      if (this.state.startDateFilter && this.state.startDateFilter !== "") {
        if (
          new Date(mercurial.start_date) < new Date(this.state.startDateFilter)
        )
          return null;
      }

      if (this.state.endDateFilter && this.state.endDateFilter !== "") {
        if (new Date(mercurial.end_date) > new Date(this.state.endDateFilter))
          return null;
      }

      // Mercurials filter
      if (
        this.state.mercurialsFilter === MercurialStatus.ACTIVE &&
        (mercurial.status !== MercurialStatus.ACTIVE ||
          new Date(mercurial.end_date) < new Date(new Date()))
      ) {
        return null;
      } else if (
        this.state.mercurialsFilter === MercurialStatus.INACTIVE &&
        mercurial.status !== MercurialStatus.INACTIVE &&
        new Date(mercurial.end_date) >= new Date(new Date())
      ) {
        return null;
      }

      if (!this.paginator.keep()) return null;

      return (
        <MercurialRow
          key={mercurial.name + "-" + mercurial.version}
          openSyncModal={(products, crmSoftware, crmEnabled, mercurial) =>
            this.openSyncModal(products, crmSoftware, crmEnabled, mercurial)
          }
          mercurial={mercurial}
          mercurials={this.props.mercurials}
          openMercurialUpdModal={(mercurial) =>
            this.openMercurialUpdModal(mercurial)
          }
          openEditModal={(mercurial) => this.openEditModal(mercurial)}
          openImgsModal={(mercurial) => this.openImgsModal(mercurial, "update")}
          openLayersModal={(mercurial) => this.openLayersModal(mercurial)}
          updateStatus={(status, mercurial) =>
            this.updateStatus(status, mercurial)
          }
        />
      );
    });

    return (
      <React.Fragment>
        {!this.props.limit && (
          <div className="row search-filters">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="form-inline">
                    <CustomLabel
                      label={this.props.intl.formatMessage({ id: "Name" })}
                      htmlFor="search_mercurial"
                      labelClassName="my-1 mr-2"
                    />
                    <input
                      id="search_mercurial"
                      className="form-control mr-sm-3"
                      type="search"
                      onChange={(e) => {
                        this.setState({ nameFilter: e.target.value });
                      }}
                      placeholder={this.props.intl.formatMessage({
                        id: "Name",
                      })}
                      disabled={disableFormInput}
                      value={this.state.nameFilter}
                    />
                    <CustomLabel
                      label={this.props.intl.formatMessage({
                        id: "Start.Date",
                      })}
                      htmlFor="search_start_date"
                      labelClassName="my-1 mr-2"
                    />
                    <input
                      id="search_start_date"
                      className="form-control mr-sm-3"
                      type="date"
                      onChange={(e) => {
                        this.setStartDateFilter(e.target.value);
                      }}
                      disabled={disableFormInput}
                      value={DateUtil.toyyyyMMdd(this.state.dateStartFilter)}
                    />
                    <CustomLabel
                      label={this.props.intl.formatMessage({ id: "End.Date" })}
                      htmlFor="search_end_date"
                      labelClassName="my-1 mr-2"
                    />
                    <input
                      id="search_end_date"
                      className="form-control mr-sm-3"
                      type="date"
                      onChange={(e) => {
                        this.setEndDateFilter(e.target.value);
                      }}
                      disabled={disableFormInput}
                      value={DateUtil.toyyyyMMdd(this.state.dateEndFilter)}
                    />
                    <CustomLabel
                      label={this.props.intl.formatMessage({ id: "Status" })}
                      htmlFor="mercurial-status-filter"
                      labelClassName="my-1 mr-2"
                    />
                    <select
                      id="mercurial-status-filter"
                      className="form-control mr-sm-3"
                      value={this.state.mercurialsFilter}
                      onChange={(e) =>
                        this.setState({
                          mercurialsFilter: parseInt(e.target.value),
                        })
                      }
                      disabled={disableFormInput}
                    >
                      <option key={2} value={2}>
                        {this.props.intl.formatMessage({ id: "All.Fem" })}
                      </option>
                      <option
                        key={MercurialStatus.ACTIVE}
                        value={MercurialStatus.ACTIVE}
                      >
                        {this.props.intl.formatMessage({
                          id: "Mercurial.Status.0.s",
                        })}
                      </option>
                      <option
                        key={MercurialStatus.INACTIVE}
                        value={MercurialStatus.INACTIVE}
                      >
                        {this.props.intl.formatMessage({
                          id: "Mercurial.Status.1.s",
                        })}
                      </option>
                    </select>
                    <MenuButton
                      onClick={() => this.resetSearchFields()}
                      hover={
                        this.areResultsFiltered() &&
                        !disableFormInput && (
                          <FormattedMessage id="Remove.Filter" />
                        )
                      }
                      variant={
                        this.areResultsFiltered()
                          ? "warning"
                          : "outline-secondary"
                      }
                      icon="filter"
                      disabled={!this.areResultsFiltered() || disableFormInput}
                    />
                    <button
                      className="btn btn-info ml-auto"
                      onClick={(e) => this.openMercurialImportModal(e)}
                    >
                      <FormattedMessage id="Mercurials.Add.Some" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <table className="table table tablee4mad">
          <thead>
            <tr>
              <th>
                <FormattedMessage id="Name" />
              </th>
              <th scope="col">
                <FormattedMessage id="Start.Date" />
              </th>
              <th scope="col">
                <FormattedMessage id="End.Date" />
              </th>
              <th scope="col">
                <FormattedMessage id="File" />
              </th>
              <th scope="col">
                <FormattedMessage id="Version" />
              </th>
              <th scope="col">
                <FormattedMessage id="Status" />
              </th>
              {crmEnabled && (
                <th scope="col">
                  <FormattedMessage id="API.CRM.Sync.Short" />
                </th>
              )}
              <th scope="col" className="col-1 text-center">
                <FormattedMessage id="Actions" />
              </th>
            </tr>
          </thead>
          <tbody>{mercurialsNode}</tbody>
        </table>

        {this.paginator.render()}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mercurials: state.mercurials,
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteMercurial: (data) => dispatch(deleteMercurial(data)),
    onUpdateMercurialStatus: (data) => dispatch(updateMercurialStatus(data)),
    onaddMercurial: (data, successCallback) =>
      dispatch(addMercurial(data, successCallback)),
    onAddProductsImgs: (mercurialId, data, successCallback) =>
      dispatch(addProductsImgs(mercurialId, data, successCallback)),
    onAddFamilyImgs: (mercurialId, data, successCallback) =>
      dispatch(addFamilyImgs(mercurialId, data, successCallback)),
    onUpdateMercurial: (data, successCallback) =>
      dispatch(updateMercurial(data, successCallback)),
    onFindArticlesMust: (data, successCallback, failureCallback) =>
      dispatch(findArticlesMust(data, successCallback, failureCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Mercurials));
