import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";

import FileDropZone from "../sub/FileDropZone";
import Icon from "../sub/Icon.js";

class MercurialUpdModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      file: null,
    };
  }

  close() {
    this.props.closeModal();
  }

  onDropFile(file) {
    this.setState({ file: file });
  }

  onComplete() {
    this.setState({ disabled: true });

    this.props.onComplete(this.props.mercurial, this.state.file);
  }

  disableButton() {
    return !this.state.file || this.state.disabled;
  }

  render() {
    return (
      <Modal
        show={true}
        onHide={() => this.close()}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Mercurials.Update" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h4 className="mb-3 w-100 text-center">
            <FormattedMessage id="Mercurials.Update.Upload" />
          </h4>
          <p className="mb-3 w-100 text-center font-weight-light">
            <FormattedMessage id="Mercurials.Update.Upload.Info" />
          </p>

          <div className="card text-white bg-warning  mb-3">
            <div className="card-header">
              <Icon icon="triangle-exclamation" className="mr-2" />
              <FormattedMessage id="Mercurials.Update.Warning.Title" />
            </div>

            <div className="card-body">
              <p className="card-text">
                <FormattedMessage id="Mercurials.Update.Warning.Content" />
              </p>
            </div>
          </div>

          <FileDropZone
            onDropFile={(file) => this.onDropFile(file)}
            acceptedExtensions={["xls", "xlsx"]}
            multiple={false}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.close()}>
            <FormattedMessage id="Cancel" />
          </Button>
          <Button
            variant="btn btn-info"
            onClick={() => this.onComplete()}
            disabled={this.disableButton()}
          >
            <FormattedMessage id="Update" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default MercurialUpdModal;
