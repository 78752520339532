import React from "react";
import { connect } from "react-redux";
import {
  getMaintenances,
  deleteMaintenance,
} from "../../actions/maintenances/maintenances";
import DateUtil from "../../util/DateUtil";
import PDFUtil from "../../util/PDFUtil";
import EqState from "../../enums/EqState";
import { Badge } from "react-bootstrap";
import { injectIntl } from "react-intl";
import APIUrl from "../../APIUrl";
import ConfirmationModal from "../sub/modals/ConfirmationModal";
import Role from "../../enums/Roles";
import { FormattedMessage } from "react-intl";
import ActionMenu from "../sub/ActionMenu";
class MaintenanceRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,

      disabled: null,

      collapsed: false,
      displayPDF: false,
      disableExport: false,
    };
  }

  close() {
    this.props.closeModal();
  }

  closeModal() {
    this.setState({ modal: null });
  }

  updateParentState(data) {
    this.props.updateParentState(data);
  }

  togglePDF(pdfElemId, pdfFileName) {
    if (this.state.disableExport) return;

    if (process.env.REACT_APP_PDF_OVERVIEW === "true") {
      // Possibility n°1 (dev env) : display preview of PDF (without header, footer, pages etc.)
      this.setState({ displayPDF: !this.state.displayPDF });
    } else {
      // Possibility n°2 (prod env) : directly save to PDF
      this.setState({ disableExport: true });
      var successCallback = () => this.setState({ disableExport: false });
      PDFUtil.toPDF(
        this.props.generalSettings,
        this.props.company,
        pdfElemId,
        pdfFileName,
        "/css/pdf.css",
        successCallback,
      );
    }
  }

  deleteMaintenance(maintenance, patientId) {
    let data = { maintenance: maintenance, patient_id: patientId };
    let title = this.props.intl.formatMessage({ id: "Warning" });
    let content = this.props.intl.formatMessage({
      id: "Confirm.Maintenance.Removal",
    });
    var successCallback = () =>
      this.props.onDeleteMaintenance(data, this.props.closeModal());
    this.props.setModal(
      <ConfirmationModal
        isOpen={true}
        title={title}
        content={content}
        context="danger"
        successCallback={successCallback}
        closeModal={() => this.props.closeModal()}
      />,
    );
  }

  render() {
    var maintenance = this.props.maintenance;
    var maintenanceDate = null;

    let alert = (
      <Badge variant="success" className="w-100">
        {this.props.intl.formatMessage({
          id: "EqState." + maintenance.general_state,
        })}
      </Badge>
    );

    switch (true) {
      case maintenance.general_state === EqState.AVERAGE:
        alert = (
          <Badge variant="warning" className="w-100">
            {this.props.intl.formatMessage({
              id: "EqState." + maintenance.general_state,
            })}
          </Badge>
        );
        break;
      case maintenance.general_state === EqState.BAD:
        alert = (
          <Badge variant="danger" className="w-100">
            {this.props.intl.formatMessage({
              id: "EqState." + maintenance.general_state,
            })}
          </Badge>
        );
        break;
      default:
        break;
    }

    maintenanceDate =
      maintenance.signature_date === undefined
        ? DateUtil.toDate(maintenance.creation_date)
        : DateUtil.toDate(maintenance.signature_date);

    let menuItems = [];

    menuItems.push(
      {
        icon: "file-pdf",
        action: () =>
          window.open(
            encodeURI(
              APIUrl.getMaintenancesPDF +
                this.props.patient._id +
                "/" +
                maintenance._id +
                "?token=" +
                APIUrl.jwtToken,
            ),
            "_system",
            "transitionstyle=fliphorizontal",
          ),
        text: <FormattedMessage id="Display.PDF" />,
        disabled: this.state.disableExport,
      },
      Role.ADMIN === this.props.user.role && {
        icon: "trash",
        text: <FormattedMessage id="Delete" />,
        action: () =>
          this.deleteMaintenance(maintenance, this.props.patient._id),
        disabled: this.state.disableExport,
      },
    );

    const menuAction = <ActionMenu items={menuItems} />;

    return (
      <React.Fragment>
        <tr key={maintenance._id}>
          <td>{maintenanceDate}</td>
          <td>{alert}</td>
          <td className="tdaction text-center">{menuAction}</td>
        </tr>

        {/* {this.state.modal} */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    generalSettings: state.generalSettings,
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteMaintenance: (data, successCallback) =>
      dispatch(deleteMaintenance(data, successCallback)),
    onGetMaintenances: (equipmentId) => dispatch(getMaintenances(equipmentId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(MaintenanceRow));
