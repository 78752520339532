import React, { useState } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Alert, Button, Modal, Tab, Tabs } from "react-bootstrap";
import FileDropZone from "../../../sub/FileDropZone";
import TextareaCounter from "react-textarea-counter";
import {
  addAuditDM,
  updateAuditDM,
  getAuditDMByName,
  addAuditDMFile,
} from "../../../../actions/settings/auditDM/admin/auditDM";
import Util from "../../../../util/Util";
import StringUtil from "../../../../util/StringUtil";
import EqSellModes from "../../../../enums/EqSellModes";
import APIUrl from "../../../../APIUrl";
import CustomLabel from "../../../sub/CustomLabel";
import AuditDMCheckpointsAdmin from "./AuditDMCheckpointsAdmin";
import AuditDMRoomsAdmin from "./AuditDMRoomsAdmin";
import MenuButton from "../../../sub/bootstrap/MenuButton";

function AuditDMModalAdmin({
  auditDMId,
  auditDMs,
  auditRoomId,
  closeModal,
  openEditModal,
  intl,
  onGetAuditDMByName,
  onAddAuditDMFile,
  onAddAuditDM,
  onUpdateAuditDM,
  auditRooms,
}) {
  const getAuditDM = (auditDMId) => {
    if (!auditDMId) return null;

    for (const auditDM of auditDMs) {
      if (auditDM._id === auditDMId) return auditDM;
    }
  };

  const auditDM = getAuditDM(auditDMId);
  const [name, setName] = useState(auditDM ? auditDM.name : "");
  const [nameError, setNameError] = useState(null);
  const [roomId, setRoomId] = useState(
    auditDM ? auditDM.currentRoom._id : auditRoomId,
  );
  const [text, setText] = useState(auditDM ? auditDM.text : "");
  const [type, setType] = useState(auditDM ? auditDM.type.toString() : "");
  const [refundable, setRefundable] = useState(
    auditDM ? auditDM.refundable : false,
  );
  const [purchaseRenewal, setPurchaseRenewal] = useState(
    auditDM ? auditDM.purchase_renewal : "0",
  );
  const [prescriptionTypeVteText, setPrescriptionTypeVteText] = useState(
    auditDM ? auditDM.prescription_type_vte_text : "",
  );
  const [prescriptionTypeLocText, setPrescriptionTypeLocText] = useState(
    auditDM ? auditDM.prescription_type_loc_text : "",
  );
  const [file, setFile] = useState(null);
  const [readonlyName, setReadOnlyName] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [disabledFileUpload, setDisabledFileUpload] = useState(false);

  const closeAndReload = (dm) => {
    if (!dm) return;
    setNameError(null);
    setReadOnlyName(true);
    const dmFromReduxData = getAuditDM(dm._id);
    closeModal();
    openEditModal(dmFromReduxData);
    setRoomId(dmFromReduxData ? dmFromReduxData.currentRoom._id : auditRoomId);
    setText(dmFromReduxData ? dmFromReduxData.text : "");
    setType(dmFromReduxData ? dmFromReduxData.type.toString() : "");
    setRefundable(dmFromReduxData ? dmFromReduxData.refundable : false);
    setPurchaseRenewal(dmFromReduxData ? dmFromReduxData.purchase_renewal : "");
    setPrescriptionTypeVteText(
      dmFromReduxData ? dmFromReduxData.prescription_type_vte_text : "",
    );
    setPrescriptionTypeLocText(
      dmFromReduxData ? dmFromReduxData.prescription_type_loc_text : "",
    );
  };

  const formatName = (name) => {
    // remove diacritics and set to uppercase
    return StringUtil.removeDiacritics(name).toUpperCase();
  };

  const onChange = (key, value) => {
    if (key === "name") setName(value);
    else if (key === "roomId") setRoomId(value);

    const nameValue = document
      .getElementById("name-material-dm")
      .value.toUpperCase();
    const roomIdValue = roomId;

    if (key !== "name" && key !== "room_id") return;

    if (Util.emptyString(nameValue) || Util.emptyString(roomIdValue)) return;

    if (auditDMId) {
      if (nameValue === auditDM.name && roomIdValue === auditDM.room_id) return;
      isExist(nameValue);
    } else {
      isExist(nameValue);
    }
  };

  const isExist = (name) => {
    const successCallback = (auditDM) => {
      // We display an error in create mode ONLY if auditDM is not associated to current room
      //console.log(auditDM);

      if (!auditDM || !auditDM.rooms) return setNameError(null);

      const alertError = (
        <Alert variant="warning" className="mt-2 mb-0">
          <div className="mb-2">
            {auditDM.rooms.find((room) => room.room_id === roomId) ? (
              <FormattedMessage id="Material.Exist.Warning.Message.1" />
            ) : (
              <FormattedMessage id="Material.Exist.Warning.Message.2" />
            )}
          </div>
          <div className="text-center">
            <button
              className="btn btn-sm btn-warning"
              onClick={() => closeAndReload(auditDM)}
            >
              <FormattedMessage id="Click.Here.To.Open.Related.Record" />
            </button>
          </div>
        </Alert>
      );

      setNameError(alertError);
    };

    onGetAuditDMByName(name, successCallback);
  };

  const onDropFile = (file, clearCallback) => {
    if (!file || file.length === 0) return;

    // Create mode. Store the file and wait for the onComplete event
    if (!auditDMId) return setFile(file);

    // Update mode. Immediately send file to BE
    setDisabledFileUpload(true);

    let formData = new FormData();
    formData.append(file.name, file);

    const successCallback = () => {
      setDisabledFileUpload(false);
      clearCallback();
    };

    // Send to BE
    onAddAuditDMFile(auditDMId, roomId, formData, successCallback);
  };

  const onSubmit = () => {
    const sendfile = (eq) => {
      if (!file || file.length === 0) return closeModal();

      let formData = new FormData();
      formData.append(file.name, file);

      onAddAuditDMFile(eq._id, eq.room_id, formData, () => closeModal());
    };

    const addAuditDM = (auditDM) => {
      setDisabled(true);
      setDisabledFileUpload(true);

      onAddAuditDM(auditDM, sendfile);
    };

    const imageExist = file ? true : false;

    const data = {
      name: name.toUpperCase().trim(),
      rooms: roomId,
      text: text,
      type: type,
      refundable: refundable,
      purchase_renewal: purchaseRenewal,
      prescription_type_vte_text: prescriptionTypeVteText,
      prescription_type_loc_text: prescriptionTypeLocText,
      image: imageExist,
    };

    addAuditDM(data);
  };

  const onUpdate = (updatedField, updatedValue) => {
    if (!auditDMId || checkErrors()) return;

    const data = {
      auditDMId: auditDMId,
      updatedField: updatedField,
      updatedValue: updatedValue,
    };

    onUpdateAuditDM(data);
  };

  const checkErrors = () => {
    return (
      nameError ||
      disabled ||
      disabledFileUpload ||
      Util.emptyString(name) ||
      Util.emptyString(type) ||
      Util.emptyString(roomId)
    );
  };

  const roomSelectOptions =
    auditRooms &&
    auditRooms.map((auditRoom) => {
      return (
        <option key={auditRoom._id} value={auditRoom._id}>
          {auditRoom.name}{" "}
        </option>
      );
    });

  const typeDMSelectOptions = Object.values(EqSellModes).map((EqSellMode) => {
    return (
      <option key={EqSellMode} value={EqSellMode}>
        {intl.formatMessage({ id: "EqSellMode." + EqSellMode })}
      </option>
    );
  });

  let img;
  if (auditDMId) {
    if (!auditDM) return null;

    const imgSrc =
      APIUrl.getAuditDMImg +
      auditDM._id +
      "/" +
      Math.random() +
      "?token=" +
      APIUrl.jwtToken;
    img = (
      <img
        src={imgSrc}
        className="col-form-label m-3"
        alt={"audit_DM_img" + Math.random()}
        height="150"
        width="150"
      />
    );
  }

  return (
    <Modal
      show={true}
      onHide={() => closeModal()}
      backdrop="static"
      keyboard={false}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {auditDM ? (
            <FormattedMessage id="Dm.Details" />
          ) : (
            <FormattedMessage id="Add.DM" />
          )}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Tabs id="dmPropsTabs">
          <Tab
            eventKey="dmPropsTabs-general"
            title={intl.formatMessage({ id: "General.Informations" })}
          >
            {auditDM && (
              <div className="d-none d-lg-block w-150 rounded light-card text-center">
                <div className="m-0 p-2">
                  {img}
                  {auditDM.image && (
                    <MenuButton
                      icon="trash"
                      variant="outline-dark"
                      onClick={(e) => onUpdate("image", false)}
                      hover={<FormattedMessage id="Restore.Default.Image" />}
                    />
                  )}
                </div>
              </div>
            )}

            <div className="form-group row">
              <CustomLabel
                label={intl.formatMessage({ id: "Name" })}
                htmlFor="name"
                labelClassName="col-12 col-md-4 col-form-label"
                required
              />
              <div id="name" className="col-12 col-md-8">
                <input
                  className="form-control"
                  type="text"
                  id="name-material-dm"
                  autoComplete="off"
                  readOnly={readonlyName}
                  value={name}
                  onChange={(e) => onChange("name", formatName(e.target.value))}
                  onBlur={(e) => onUpdate("name", formatName(name))}
                />
                {nameError}
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={intl.formatMessage({ id: "Image" })}
                htmlFor="dm"
                labelClassName="col-12 col-md-4 col-form-label pt-0"
              />
              <div className="col-12 col-md-8">
                <FileDropZone
                  disabled={disabledFileUpload}
                  onDropFile={(file, clearCallback) =>
                    onDropFile(file, clearCallback)
                  }
                  acceptedExtensions={["webp", "jpg", "png", "jpeg"]}
                />
              </div>
            </div>

            <div className="form-group row">
              <label
                htmlFor="text"
                className="col-12 col-md-4 col-form-label pt-0"
              >
                <FormattedMessage id="Text" />
              </label>
              <div id="text" className="col-12 col-md-8">
                <TextareaCounter
                  countLimit={1000}
                  className="textareaCounter"
                  type="text"
                  id="text"
                  rows="7"
                  initialValue={text}
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  onBlur={(e) => onUpdate("text", text)}
                />
              </div>
            </div>
          </Tab>

          <Tab
            eventKey="dmPropsTabs-environment"
            title={intl.formatMessage({ id: "Associated.Environment" })}
          >
            <Alert variant="info">
              <FormattedMessage id="Environment.Info" />
            </Alert>
            {auditDMId && <AuditDMRoomsAdmin currentDM={auditDM} />}
            {!auditDMId && (
              <div className="form-group row">
                <CustomLabel
                  label={intl.formatMessage({ id: "Audit.Room" })}
                  htmlFor="room"
                  labelClassName="col-12 col-md-4 col-form-label"
                  required
                />
                <div className="col-12 col-md-8">
                  <select
                    id="room-id-material-dm"
                    className="form-control w-100"
                    value={roomId}
                    onChange={(e) => onChange("roomId", e.target.value)}
                    onBlur={(e) => onUpdate("room_id", roomId)}
                  >
                    <option value="">
                      {intl.formatMessage({ id: "Select" })}
                    </option>
                    {roomSelectOptions}
                  </select>
                </div>
              </div>
            )}
          </Tab>

          <Tab
            eventKey="dmPropsTabs-prescription"
            title={intl.formatMessage({ id: "Prescription" })}
          >
            <div className="form-group row">
              <CustomLabel
                label={intl.formatMessage({ id: "Type" })}
                htmlFor="type"
                labelClassName="col-12 col-md-4 col-form-label"
                required
              />
              <div className="col-12 col-md-8">
                <select
                  id="type"
                  className="form-control w-100"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  onBlur={(e) => onUpdate("type", type)}
                >
                  <option value="">
                    {intl.formatMessage({ id: "Select" })}
                  </option>
                  {typeDMSelectOptions}
                </select>
              </div>
            </div>

            {(type === "0" || type === "2") && (
              <div className="form-group row">
                <label
                  htmlFor="purchase_sail"
                  className="col-12 col-md-4 col-form-label"
                >
                  <FormattedMessage id="Purchase.Sails" />
                </label>
                <div className="col-12 col-md-8">
                  <select
                    className="form-control d-inline"
                    id="purchase_renewal"
                    value={purchaseRenewal}
                    onChange={(e) => setPurchaseRenewal(e.target.value)}
                    onBlur={(e) =>
                      onUpdate("purchase_renewal", purchaseRenewal)
                    }
                  >
                    <option value="0" defaultValue={purchaseRenewal === "0"}>
                      {intl.formatMessage({ id: "None" })}
                    </option>
                    <option value="1" defaultValue={purchaseRenewal === "1"}>
                      1 {intl.formatMessage({ id: "Year" })}
                    </option>
                    <option value="2" defaultValue={purchaseRenewal === "2"}>
                      2 {intl.formatMessage({ id: "Years" })}
                    </option>
                    <option value="3" defaultValue={purchaseRenewal === "3"}>
                      3 {intl.formatMessage({ id: "Years" })}
                    </option>
                    <option value="5" defaultValue={purchaseRenewal === "5"}>
                      5 {intl.formatMessage({ id: "Years" })}
                    </option>
                  </select>
                </div>
              </div>
            )}

            <div className="form-group row">
              <CustomLabel
                label={intl.formatMessage({ id: "Refundable" })}
                htmlFor="refundable"
                labelClassName="col-12 col-md-4 col-form-label"
              />
              <div className="col-12 col-md-8">
                <div className="custom-control custom-switch mx-auto switch-success text-left">
                  <input
                    type="checkbox"
                    id="refundable-switch"
                    className="custom-control-input switch-bg-blue"
                    checked={refundable}
                    value={refundable}
                    onChange={(e) => setRefundable(!refundable)}
                    onBlur={(e) => onUpdate("refundable", refundable)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="refundable-switch"
                  ></label>
                </div>
              </div>
            </div>

            {refundable === true && (type === "1" || type === "2") && (
              <div className="form-group row">
                <CustomLabel
                  label={intl.formatMessage({ id: "Prescription.Loc" })}
                  htmlFor="prescription_type_loc_text"
                  labelClassName="col-12 col-md-4 col-form-label pt-0"
                />
                <div
                  id="prescription_type_loc_text"
                  className="col-12 col-md-8"
                >
                  <TextareaCounter
                    countLimit={1000}
                    className="textareaCounter"
                    type="text"
                    initialValue={prescriptionTypeLocText}
                    id="prescription_type_loc_text"
                    rows="4"
                    onChange={(e) => setPrescriptionTypeLocText(e.target.value)}
                    onBlur={(e) =>
                      onUpdate(
                        "prescription_type_loc_text",
                        prescriptionTypeLocText,
                      )
                    }
                  />
                </div>
              </div>
            )}

            {refundable === true && (type === "0" || type === "2") && (
              <div className="form-group row">
                <CustomLabel
                  label={intl.formatMessage({ id: "Prescription.Sail" })}
                  htmlFor="prescription_type_vte_text"
                  labelClassName="col-12 col-md-4 col-form-label pt-0"
                />
                <div
                  id="prescription_type_vte_text"
                  className="col-12 col-md-8"
                >
                  <TextareaCounter
                    countLimit={1000}
                    className="textareaCounter"
                    type="text"
                    initialValue={prescriptionTypeVteText}
                    id="prescription_type_vte_text"
                    rows="4"
                    onChange={(e) => setPrescriptionTypeVteText(e.target.value)}
                    onBlur={(e) =>
                      onUpdate(
                        "prescription_type_vte_text",
                        prescriptionTypeVteText,
                      )
                    }
                  />
                </div>
              </div>
            )}
          </Tab>

          <Tab
            eventKey="dmPropsTabs-checkpoints"
            disabled={!auditDMId}
            title={intl.formatMessage({ id: "Checkpoints" })}
          >
            {auditDMId && <AuditDMCheckpointsAdmin currentDM={auditDM} />}
          </Tab>
        </Tabs>
      </Modal.Body>

      {!auditDMId && (
        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeModal()}>
            <FormattedMessage id="Cancel" />
          </Button>
          <Button
            variant="btn btn-info"
            onClick={() => onSubmit()}
            disabled={checkErrors()}
          >
            <FormattedMessage id="Add" />
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    auditRooms: state.auditRooms,
    auditDMs: state.auditDMs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAuditDM: (data, successCallback) =>
      dispatch(addAuditDM(data, successCallback)),
    onGetAuditDMByName: (name, successCallback) =>
      dispatch(getAuditDMByName(name, successCallback)),
    onUpdateAuditDM: (data) => dispatch(updateAuditDM(data)),
    onAddAuditDMFile: (auditDMId, roomId, data, successCallback) =>
      dispatch(addAuditDMFile(auditDMId, roomId, data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AuditDMModalAdmin));
