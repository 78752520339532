import React from "react";
import { connect } from "react-redux";
import OrderProducts from "./OrderProducts";
import DateUtil from "../../util/DateUtil";
import { FormattedMessage, injectIntl } from "react-intl";
import APIUrl from "../../APIUrl";
import Maths from "../../util/Maths";
import OrderStatus from "../../enums/OrderStatus";

class OrderPDF extends React.Component {
  render() {
    let client = null;
    let admin = null;

    /** SET DISPLAY FOR PRESCRIBER(Client) */
    if (this.props.client) {
      client = (
        <div className="address">
          <h1>
            <FormattedMessage id="Order.Passed.By" />
          </h1>
          <p>{this.props.client.first_name + " " + this.props.client.name}</p>
          {this.props.client.address && <p> {this.props.client.address} </p>}
          {this.props.client.postal_code && (
            <p> {this.props.client.postal_code} </p>
          )}
          {this.props.client.city && <p> {this.props.client.city} </p>}
        </div>
      );
    }

    /** SET DISPLAY FOR SALESREP IF FIND */
    for (let collaborator of this.props.collaborators) {
      if (
        collaborator.prescribers &&
        collaborator.prescribers.find((e) => {
          return e.prescriber_id === this.props.client._id;
        })
      ) {
        admin = (
          <div>
            <p>
              <FormattedMessage id="Your.Contact" />:{" "}
              {collaborator.first_name + " " + collaborator.name}
            </p>
            {collaborator.phone && (
              <p>
                <FormattedMessage id="Phone" />: {collaborator.phone}
              </p>
            )}
            {collaborator.email && (
              <p>
                <FormattedMessage id="Email" />: {collaborator.email}
              </p>
            )}
          </div>
        );
      }
    }

    let totalHT = Maths.round(
      this.props.order.total_ht +
        this.props.order.shipping_costs +
        this.props.order.urgent_costs,
    );

    let totalTTC = Maths.round(
      this.props.order.total_ttc +
        this.props.order.shipping_costs * 1.2 +
        this.props.order.urgent_costs * 1.2,
    );

    let totalTax = Maths.round(totalTTC - totalHT);

    const orderDate = this.props.order.creation_date
      ? DateUtil.toDate(new Date(this.props.order.creation_date).getTime())
      : "-";

    return (
      <div id={this.props.id} className="pdf-container">
        <div className="pdf">
          <div className="div-logo">
            <div className="w-50-perc in-block">
              {this.props.generalSettings.logo && (
                <div className="e4mad-logo-container">
                  <img
                    src={
                      APIUrl.getGeneralSettingsLogo +
                      this.props.generalSettings._id +
                      "/" +
                      Math.random() +
                      "?token=" +
                      APIUrl.jwtToken
                    }
                    className="e4mad-logo"
                    alt="logo"
                  />
                </div>
              )}
              <div className="stamp">
                <div className="d-inline-block">
                  <p>
                    <FormattedMessage
                      id="Order.Info"
                      values={{
                        orderNum: this.props.order.ref,
                        orderDate: orderDate,
                      }}
                    />
                  </p>
                  <p className="newOrder">
                    {this.props.order.status === OrderStatus.NEW
                      ? "(" +
                        this.props.intl.formatMessage({
                          id: "Waiting.Delivery",
                        }) +
                        ")"
                      : ""}
                    {this.props.order.status === OrderStatus.BLOCKED
                      ? "(" +
                        this.props.intl.formatMessage({
                          id: "Delivery.In.Progress",
                        }) +
                        ")"
                      : ""}
                  </p>
                </div>
                {this.props.order.shipping_delay &&
                  this.props.order.creation_date && (
                    <p>
                      <FormattedMessage id="Order.Estimated.Delivery.Date" />:{" "}
                      {DateUtil.toDate(
                        new Date(this.props.order.creation_date).getTime() +
                          this.props.order.shipping_delay * 24 * 3600 * 1000,
                      )}
                    </p>
                  )}
              </div>
            </div>
          </div>

          <div className="div-responsables">
            <div className="contacts">
              {admin}
              {client}
            </div>
          </div>

          <div className="order-table">
            <div>
              <OrderProducts order={this.props.order} />
            </div>
          </div>

          <div className="summary-orderDetail">
            <div className="summary">
              <table className="table-total">
                <tbody>
                  <tr>
                    <td>
                      <FormattedMessage id="Total.Cart.Excl.Tax" />
                    </td>
                    <td className="font-bold">
                      <b>{Maths.round(this.props.order.total_ht)}€</b>
                    </td>
                  </tr>
                  {this.props.order.shipping_costs > 0 && (
                    <tr>
                      <td>
                        <FormattedMessage id="Shipping.Cost" />
                        &nbsp;
                      </td>
                      <td className="font-bold">
                        <b>{Maths.round(this.props.order.shipping_costs)}€</b>
                      </td>
                    </tr>
                  )}
                  {this.props.order.urgent_costs > 0 && (
                    <tr>
                      <td>
                        <FormattedMessage id="Emergency.Cost" />
                      </td>
                      <td className="font-bold">
                        <b>{Maths.round(this.props.order.urgent_costs)}€</b>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <FormattedMessage id="Total.Excl.Tax" />
                    </td>
                    <td className="font-bold">
                      <b>{totalHT}€</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="VAT" />
                    </td>
                    <td className="font-bold">
                      <b>{totalTax}€</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="Total.Incl.Tax" />
                    </td>
                    <td className="font-bold">
                      <b>{totalTTC}€</b>
                    </td>
                  </tr>
                  <tr className="order-detail">
                    <td>
                      <FormattedMessage id="Order.Shipping.Delay" />:
                    </td>
                    <td className="font-bold">
                      <b>
                        {this.props.order.shipping_delay}{" "}
                        <FormattedMessage id="Days" />
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    collaborators: state.collaborators,
    generalSettings: state.generalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(OrderPDF));
