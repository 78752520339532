import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Row, Tab, Tabs } from "react-bootstrap";

import Roles from "../../enums/Roles";

import ProductsBudget from "./ProductsBudget";
import OverTimeBudget from "./OverTimeBudget";
import TopProductsBudget from "./TopProductsBudget";
import Icon from "../sub/Icon.js";
import Select from "react-select";
import TableToolbar from "../sub/bootstrap/TableToolbar";

class Budget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clientId: null,
      selectedClient: null,
    };
  }

  getClient(clientId) {
    for (let client of this.props.clients) {
      if (client._id === clientId) return client;
    }
  }

  getOrders(clientId) {
    let orders = [];
    for (let order of this.props.orders) {
      let client = this.getClient(order.id_client);
      if (!client) continue;
      if (client._id === clientId) {
        orders.push(order);
      }
    }
    return orders;
  }

  getSelectOptions() {
    const options = [];
    for (const c of this.props.clients) {
      options.push({ value: c._id, label: `${c.name} ${c.first_name}` });
    }
    return options;
  }

  render() {
    const { user, intl } = this.props;
    const isClient = user.role === Roles.CLIENT;
    const orders = isClient
      ? this.props.orders
      : this.getOrders(this.state.clientId);

    return (
      <React.Fragment>
        {!isClient && (
          <TableToolbar>
            <Row className="justify-content-center align-items-center w-100">
              <Icon icon="user-doctor" className="mr-2" />
              <Select
                className="w-25 text-left"
                placeholder={<FormattedMessage id={"Select.Client"} />}
                options={this.getSelectOptions()}
                isClearable
                noOptionsMessage={() => <FormattedMessage id={"No.Result"} />}
                onChange={(option) =>
                  this.setState({
                    selectedClient: option,
                    clientId: option && option.value,
                  })
                }
                value={this.state.selectedClient}
              />
            </Row>
          </TableToolbar>
        )}

        {((!isClient && this.state.clientId) || isClient) && (
          <Tabs defaultActiveKey="budget-purchases" id="budgetTabs">
            <Tab
              eventKey="budget-purchases"
              title={intl.formatMessage({ id: "Purchases" })}
            >
              <ProductsBudget orders={orders} />
            </Tab>

            <Tab
              eventKey="budget-overtime"
              title={intl.formatMessage({ id: "Overview" })}
            >
              <OverTimeBudget orders={orders} />
            </Tab>

            <Tab
              eventKey="budget-topproducts"
              title={intl.formatMessage({ id: "Top.Products" })}
            >
              <TopProductsBudget orders={orders} />
            </Tab>
          </Tabs>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orders: state.orders,
    clients: state.clients,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Budget));
