import React from "react";
import { connect } from "react-redux";
// import StringUtil from '../../../../util/StringUtil';
import { FormattedMessage, injectIntl } from "react-intl";
import {
  deleteDeskProduct,
  updateDeskProduct,
} from "../../../../actions/settings/deskCategories/admin/deskProducts";
import Util from "../../../../util/Util";
import MenuButton from "../../../sub/bootstrap/MenuButton";

class DeskProductsRowAdmin extends React.Component {
  updateDeskProduct(deskProductId, updatedField, updatedValue) {
    console.log("updateDeskProduct", deskProductId, updatedField, updatedValue);

    if (!deskProductId) return;
    if (!this.props.deskProduct) return null;

    if (updatedField === "sortIndex" && Util.emptyString(updatedValue)) return;

    var data = {
      deskProductId: deskProductId,
      updatedField: updatedField,
      updatedValue: updatedValue + 1,
    };
    var successCallback = () => {
      var deskProducts = this.props.deskProducts;
      var deskProductsAscend = deskProducts.filter(
        (obj) => obj.sortIndex === updatedValue + 1,
      );
      data = {
        deskProductId: deskProductsAscend[0]._id,
        updatedField: updatedField,
        updatedValue: updatedValue,
      };
      this.props.onUpdatedeskProduct(data, () => {});
    };

    this.props.onUpdatedeskProduct(data, successCallback);
  }

  deleteDeskProduct(deskProductId, updatedField, updatedValue) {
    if (!deskProductId) return;
    if (updatedField === "sortIndex" && Util.emptyString(updatedValue)) return;

    var data = {
      deskProductId: deskProductId,
      updatedField: updatedField,
      updatedValue: updatedValue,
    };

    var successCallback = () => {
      var deskProducts = this.props.deskProducts;
      var deskProductsAscend = deskProducts.filter(
        (obj) => obj.sortIndex > updatedValue,
      );
      deskProductsAscend.map((deskProduct) => {
        data = {
          deskProductId: deskProduct._id,
          updatedField: updatedField,
          updatedValue: deskProduct.sortIndex - 1,
        };
        this.props.onUpdateDeskProduct(data, () => {
          return null;
        });
        return null;
      });
    };

    this.props.onDeleteDeskProduct(data, successCallback);
  }

  render() {
    // var refundable = <FormattedMessage id="No" />;

    var modalTitle = <FormattedMessage id="Confirm" />;
    var modalContent = (
      <React.Fragment>
        <div>
          <FormattedMessage id="Type.DM.Remove.Confirmation" />
        </div>
      </React.Fragment>
    );

    var successCallback = () =>
      this.deleteDeskProduct(
        this.props.deskProduct._id,
        "sortIndex",
        this.props.deskProduct.sortIndex,
      );

    //var disabled = (this.props.deskProduct._id.length <= this.props.deskProduct.sortIndex);

    return (
      <React.Fragment>
        <tr
          key={this.props.key}
          className="order-tr order-tr tablelist row-striped"
        >
          <td className="align-middle">{this.props.deskProduct.name}</td>
          <td className="text-center tdaction">
            <MenuButton
              icon="pen-to-square"
              onClick={() =>
                this.props.openEditDeskProductsModal(this.props.deskProduct)
              }
              hover={<FormattedMessage id="Modify" />}
            />
            <MenuButton
              icon="trash"
              id={"delete" + this.props.deskProduct._id}
              onClick={() =>
                this.props.openConfModal(
                  modalTitle,
                  modalContent,
                  successCallback,
                )
              }
              hover={<FormattedMessage id="Delete" />}
            />
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deskProducts: state.deskProducts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateDeskProduct: (data) => dispatch(updateDeskProduct(data)),
    onDeleteDeskProduct: (data) => dispatch(deleteDeskProduct(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(DeskProductsRowAdmin));
