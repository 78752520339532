import React from "react";
import { FormattedMessage } from "react-intl";
import { withRouter, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import Icon from "../../../sub/Icon.js";

const propTypes = {
  link: PropTypes.string.isRequired,
  countValue: PropTypes.number.isRequired,
  totalCart: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

/**
 * NavLink item for user Navbar
 * @param {String} link navlink to redirect on cart
 * @param {Number} countValue value to display on badge danger
 * @param {Number} totalCart total price of cart
 * @param {Boolean} disabled disabled link if necessary
 */
class MenuItemCart extends React.Component {
  render() {
    const { link, countValue, totalCart, disabled } = this.props;
    return (
      <React.Fragment>
        <li className="nav-item list-inline-item align-middle text-nowrap">
          <div className="cart">
            <div className="row d-flex justify-content-center mb-2">
              <div className="col-6 text-left nav-item">
                <span className="relative">
                  <FormattedMessage id="Cart" />
                  <Icon icon="cart-shopping" className="ml-1" />
                  {countValue > 0 && (
                    <span className="badge badge-danger">{countValue}</span>
                  )}
                </span>
              </div>
              <div className="col-6 text-right">
                <FormattedMessage id="Total" />
                <b> {totalCart}€</b>
              </div>
            </div>
            <div>
              <NavLink to={link} className="cart-item" disabled={disabled}>
                <button className="btn btn-outline-info">
                  <FormattedMessage id="Go.To.Cart" />
                </button>
              </NavLink>
            </div>
          </div>
        </li>
      </React.Fragment>
    );
  }
}

MenuItemCart.propTypes = propTypes;

export default withRouter(MenuItemCart);
