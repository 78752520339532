import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import DeskCategoryModalAdmin from "./DeskCategoryModalAdmin";
//import ConfirmationModal from "../../../sub/modals/ConfirmationModal";
import DeskCategoryAssociationsModalAdmin from "./DeskCategoryAssociationsModalAdmin";
import DeskProducts from "./DeskProducts";
import { Modal, Tab, Tabs } from "react-bootstrap";

class DeskCategoriesModalAdmin extends React.Component {
  close() {
    this.props.closeModal();
  }

  openDeskCategoriesModal() {
    this.setState({
      modal: (
        <DeskCategoriesModalAdmin
          isOpen={true}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  getCategory(deskCategoryId) {
    for (let p of this.props.deskCategories) {
      if (p._id === deskCategoryId) return p;
    }
  }

  render() {
    const { deskCategoryId } = this.props;
    const deskCategory = this.getCategory(deskCategoryId);

    var target = this.props.target ? this.props.target : "general";

    var aClassName = deskCategory ? "" : "disabled";

    return (
      <Modal
        show={true}
        onHide={() => this.props.closeModal()}
        backdrop={"static"}
        size={"xl"}
      >
        <Modal.Body>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => this.props.closeModal()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <Tabs defaultActiveKey={target} id="tab-desk-categ" className="mb-3">
            <Tab
              eventKey="general"
              title={<FormattedMessage id="General.Informations" />}
            >
              <DeskCategoryModalAdmin
                closeModal={() => this.close()}
                deskCategory={this.props.deskCategory}
                deskCategoryId={
                  this.props.deskCategoryId ? this.props.deskCategoryId : null
                }
              />
            </Tab>
            <Tab
              eventKey="association"
              title={<FormattedMessage id="Associations" />}
              disabled={aClassName}
            >
              <DeskCategoryAssociationsModalAdmin
                deskCategoryId={
                  this.props.deskCategoryId ? this.props.deskCategoryId : null
                }
              />
            </Tab>
            <Tab
              eventKey="product"
              title={<FormattedMessage id="Types.Products" />}
              disabled={aClassName}
            >
              <DeskProducts
                deskCategoryId={
                  this.props.deskCategoryId ? this.props.deskCategoryId : null
                }
              />
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deskCategories: state.deskCategories,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(DeskCategoriesModalAdmin));
