import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import ShippingModalAdmin from "./ShippingModalAdmin";
import ShippingCostSettingAdmin from "./ShippingCostSettingAdmin";
import {
  updateOrderSettingAdmin,
  updateShippingCostsSetting,
} from "../../../../actions/settings/ordersSettings/admin/ordersSettings";
import CustomLabel from "../../../sub/CustomLabel";
import Icon from "../../../sub/Icon.js";

class OrdersSettingsAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      shipping_delay: this.props.ordersSettingsAdmin.shipping_delay,
      min_order_amount: this.props.ordersSettingsAdmin.min_order_amount,
      urgent: this.props.ordersSettingsAdmin.urgent,
    };
  }

  openShippingModal(successCallback) {
    if (this.hasInfinity()) return;

    this.setState({
      modal: (
        <ShippingModalAdmin
          isOpen={true}
          index={-1}
          shippingCosts={this.props.ordersSettingsAdmin.shipping_costs}
          successCallback={successCallback}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  createShippingCostSetting(shippingCosts) {
    // Send to BE
    this.props.onUpdateShippingCostsSetting({ shippingCosts: shippingCosts });
  }

  onFieldChange(field, value = 0) {
    this.setState({ [field]: value });
  }

  updateOrderSettingAdmin(field, value = 0) {
    var data = {
      updatedField: field,
      updatedValue: value,
    };

    this.props.onUpdateOrderSettingAdmin(data);
  }

  hasInfinity() {
    return (
      this.props.ordersSettingsAdmin.shipping_costs.length !== 0 &&
      !this.props.ordersSettingsAdmin.shipping_costs[
        this.props.ordersSettingsAdmin.shipping_costs.length - 1
      ].max
    );
  }

  render() {
    if (this.props.ordersSettingsAdmin.length === 0) return null;
    // Shipping modal set up
    var successCallback = (e) =>
      this.createShippingCostSetting(
        this.props.ordersSettingsAdmin.shipping_costs,
      );

    let shippingCostNode = null;
    if (this.props.ordersSettingsAdmin.shipping_costs.length !== 0) {
      shippingCostNode = (
        <ShippingCostSettingAdmin
          ordersSettingsAdmin={this.props.ordersSettingsAdmin}
        />
      );
    }

    return (
      <React.Fragment>
        {this.state.modal}
        <div className="col-12 col-lg-7 col-xl-6">
          <div className="form-group row">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Shipping.Delay" })}
              htmlFor="shippingDelay"
              labelClassName="col-12 col-md-7 col-form-label"
            />
            <div className="col-12 col-md-5">
              <input
                type="number"
                className="form-control"
                id="shippingDelay"
                min="0"
                value={this.state.shipping_delay}
                onChange={(e) =>
                  this.onFieldChange("shipping_delay", e.target.value)
                }
                onBlur={(e) =>
                  this.updateOrderSettingAdmin("shipping_delay", e.target.value)
                }
              />
            </div>
          </div>
          <div className="form-group row">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Min.Price" })}
              htmlFor="minPrice"
              labelClassName="col-12 col-md-7 col-form-label"
            />
            <div className="col-12 col-md-5">
              <input
                type="number"
                className="form-control"
                id="minPrice"
                min="0"
                value={this.state.min_order_amount}
                onChange={(e) =>
                  this.onFieldChange("min_order_amount", e.target.value)
                }
                onBlur={(e) =>
                  this.updateOrderSettingAdmin(
                    "min_order_amount",
                    e.target.value,
                  )
                }
              />
            </div>
          </div>
          <div className="form-group row">
            <CustomLabel
              label={this.props.intl.formatMessage({
                id: "Settings.Urgent.Cost",
              })}
              htmlFor="urgent"
              labelClassName="col-12 col-md-7 col-form-label"
            />
            <div className="col-12 col-md-5">
              <input
                type="number"
                className="form-control"
                id="urgent"
                min="0"
                value={this.state.urgent || 0}
                onChange={(e) => this.onFieldChange("urgent", e.target.value)}
                onBlur={(e) =>
                  this.updateOrderSettingAdmin("urgent", e.target.value)
                }
              />
            </div>
          </div>
          <div className="form-group row d-flex align-items-center">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Shipping.Cost" })}
              htmlFor="shippingCost"
            />
            <div className="">
              <Icon
                icon="square-plus"
                clickable
                size="xl"
                onClick={(e) => this.openShippingModal(successCallback)}
                disabled={this.hasInfinity()}
                hover={!this.hasInfinity() && <FormattedMessage id="Add" />}
              />
            </div>
          </div>
          {shippingCostNode}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    ordersSettingsAdmin: state.ordersSettingsAdmin,
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateOrderSettingAdmin: (data) =>
      dispatch(updateOrderSettingAdmin(data)),
    onUpdateShippingCostsSetting: (data) =>
      dispatch(updateShippingCostsSetting(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(OrdersSettingsAdmin));
