import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import TextareaCounter from "react-textarea-counter";

import Util from "../../util/Util";

import RequestTypes from "../../enums/RequestTypes";
import EstimateStatus from "../../enums/EstimateStatus";

import { createRequestAdmin } from "../../actions/requests/requests";
import CustomLabel from "../sub/CustomLabel";

class EstimateModalAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      client_id: "",
      precisions: "",
      disabled: false,
      disabledInputs: false,
    };
  }

  create() {
    if (this.hasEmptyField() || this.state.disabled) return;

    let data = {
      client_id: this.state.client_id,
      request_type: RequestTypes.NEW_MATERIAL,
      precisions: this.state.precisions,
      status: EstimateStatus.PENDING,
    };

    this.setState({ disabled: true });

    this.props.onCreateRequestAdmin(data, () => this.props.close());
  }

  hasEmptyField() {
    return (
      Util.emptyString(this.state.client_id) ||
      Util.emptyString(this.state.precisions)
    );
  }

  onChange(key, value) {
    this.setState({ [key]: value });
  }

  render() {
    let clientsSelectOptions = null;

    if (this.props.clients.length > 0) {
      clientsSelectOptions = this.props.clients.map((client) => {
        return (
          <option key={client._id} value={client._id}>
            {client.first_name} {client.name}
          </option>
        );
      });
    }

    return (
      <Modal
        show={true}
        onHide={() => this.props.close()}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Create.Request" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group row">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Client" })}
              htmlFor="clientId"
              labelClassName="col-12 col-md-5 col-form-label"
              required
            />
            <div className="col-12 col-sm-7">
              <select
                id="clientId"
                className="form-control"
                value={this.state.client_id}
                onChange={(e) => this.onChange("client_id", e.target.value)}
                disabled={this.state.disabledInputs}
              >
                <option value=""></option>
                {clientsSelectOptions}
              </select>
            </div>
          </div>

          <div className="form-group row">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Precisions" })}
              htmlFor="precisions"
              labelClassName="col-12 col-md-5 col-form-label"
              required
            />
            <div className="col-12 col-sm-7">
              <TextareaCounter
                countLimit={1000}
                className="textareaCounter"
                id="precisions"
                initialValue={this.state.precisions}
                onChange={(e) => this.onChange("precisions", e.target.value)}
                disabled={this.state.disabledInputs}
              />
            </div>
          </div>
        </Modal.Body>

        {!this.state.disabledInputs && (
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.props.close()}>
              <FormattedMessage id="Cancel" />
            </Button>
            <Button
              variant="btn btn-info"
              onClick={() => this.create()}
              disabled={this.state.disabled || this.hasEmptyField()}
            >
              <FormattedMessage id="Pass" />
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.clients,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateRequestAdmin: (data, successCallback) =>
      dispatch(createRequestAdmin(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(EstimateModalAdmin));
