import React from "react";
import { FormattedMessage } from "react-intl";
import { withRouter, NavLink } from "react-router-dom";
import PropTypes from "prop-types";

const propTypes = {
  link: PropTypes.string.isRequired,
  formattedMessageId: PropTypes.string.isRequired,
  isCountBadge: PropTypes.bool,
  countValue: PropTypes.number,
};

const defaultProps = {
  isCountBadge: false,
  countValue: -1,
};

/**
 * NavLink item for user Navbar
 * @param {String} link navlink to redirect
 * @param {String} formattedMessageId id to get formatted message
 * @param {Boolean} isCountBadge set true/false to activate badge danger
 * @param {Number} countValue value to display on badge danger
 */
class MenuItem extends React.Component {
  render() {
    const { link, formattedMessageId, isCountBadge, countValue, event } =
      this.props;
    return (
      <React.Fragment>
        <li className="nav-item list-inline-item align-middle text-nowrap mb-3 mt-3 mb-sm-0 mt-sm-0">
          <NavLink
            className="w-100 p-3"
            to={link}
            exact
            activeClassName="selected"
            onClick={event}
          >
            <FormattedMessage id={formattedMessageId} />
            {isCountBadge && countValue > 0 && (
              <span className="badge badge-danger">{countValue}</span>
            )}
          </NavLink>
        </li>
      </React.Fragment>
    );
  }
}

MenuItem.propTypes = propTypes;
MenuItem.defaultProps = defaultProps;

export default withRouter(MenuItem);
