import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { deleteAuditDM } from "../../../../actions/settings/auditDM/admin/auditDM";
import { Alert } from "react-bootstrap";
import { nanoid } from "nanoid";
import ActionMenu from "../../../sub/ActionMenu";

function AuditDMRowAdmin({
  auditDM,
  openEditModal,
  openConfModal,
  onDeleteAuditDM,
  intl,
}) {
  const deleteAuditDM = (auditDM) => {
    if (!auditDM) return;

    const data = {
      auditDMId: auditDM._id,
      roomId: auditDM.room_id,
    };

    onDeleteAuditDM(data);
  };

  const refundable = (
    <FormattedMessage id={auditDM && auditDM.refundable ? "Yes" : "No"} />
  );

  const modalTitle = <FormattedMessage id="Warning" />;
  const modalContent = (
    <div className="text-justify">
      <Alert variant="danger">
        <FormattedMessage id="Type.DM.Remove.Confirmation.1" />
        <br />
        <FormattedMessage id="Irrevocable.Warning" />
      </Alert>
      <p>
        <FormattedMessage id="Type.DM.Remove.Confirmation.2" />
      </p>
    </div>
  );
  const successCallback = () => deleteAuditDM(auditDM);

  let menuItems = [];

  menuItems.push(
    {
      icon: "pen-to-square",
      action: () => openEditModal(auditDM),
      text: <FormattedMessage id="Modify" />,
    },
    {
      icon: "trash",
      action: () => openConfModal(modalTitle, modalContent, successCallback),
      text: <FormattedMessage id="Delete" />,
    },
  );

  const menuAction = <ActionMenu items={menuItems} />;

  return (
    <tr key={nanoid()} className="order-tr order-tr tablelist row-striped">
      <td>{auditDM.currentRoom.name.toUpperCase()}</td>
      <td>{auditDM.name.toUpperCase()}</td>
      <td>{refundable}</td>
      <td>{intl.formatMessage({ id: "EqSellMode." + auditDM.type })}</td>
      <td className="text-center">{menuAction}</td>
    </tr>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteAuditDM: (data) => dispatch(deleteAuditDM(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AuditDMRowAdmin));
