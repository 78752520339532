import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import DeskProductsModalAdmin from "./DeskProductsModalAdmin";
import DeskProductsTableAdmin from "./DeskProductsTableAdmin";
import ConfirmationModal from "../../../sub/modals/ConfirmationModal";

class DeskProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: null,
    };
  }

  openDeskProductsModalAdmin() {
    this.setState({
      modal: (
        <DeskProductsModalAdmin
          deskCategoryId={this.props.deskCategoryId}
          isOpen={true}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  openEditDeskProductsModal(deskProduct) {
    this.setState({
      modal: (
        <DeskProductsModalAdmin
          isOpen={true}
          deskCategoryId={this.props.deskCategoryId}
          closeModal={() => this.closeModal()}
          deskProductId={deskProduct._id}
        />
      ),
    });
  }

  openConfModal(title, content, successCallback) {
    this.setState({
      modal: (
        <ConfirmationModal
          isOpen={true}
          title={title}
          content={content}
          successCallback={successCallback}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    //console.log('CLOSE MAIN');
    this.setState({ modal: null });
  }

  render() {
    const { deskProducts } = this.props;

    var deskProductsCategory = deskProducts.filter(
      (ele) => ele.category_id === this.props.deskCategoryId,
    );

    return (
      <React.Fragment>
        <button
          className="btn btn-info m-auto"
          onClick={(e) => this.openDeskProductsModalAdmin()}
        >
          <FormattedMessage id="Add.Type.Product" />
        </button>

        {(!deskProductsCategory || deskProductsCategory.length <= 0) && (
          <div className="alert alert-secondary mt-3" role="alert">
            <FormattedMessage id="Empty.Types.Products" />
          </div>
        )}

        {deskProductsCategory && deskProductsCategory.length !== 0 && (
          <DeskProductsTableAdmin
            deskCategoryId={this.props.deskCategoryId}
            deskProductsCategory={deskProductsCategory}
            openEditDeskProductsModal={(deskProduct) =>
              this.openEditDeskProductsModal(deskProduct)
            }
            openConfModal={(title, content, successCallback) =>
              this.openConfModal(title, content, successCallback)
            }
          />
        )}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deskProducts: state.deskProducts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeskProducts);
