import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import DeskProductsRowAdmin from "./DeskProductsRowAdmin";

class DeskProductsTableAdmin extends React.Component {
  render() {
    let deskProductRowNode = this.props.deskProductsCategory.map(
      (deskProduct) => {
        return (
          <DeskProductsRowAdmin
            key={deskProduct._id}
            deskProduct={deskProduct}
            openEditDeskProductsModal={(deskProduct) =>
              this.props.openEditDeskProductsModal(deskProduct)
            }
            openConfModal={(title, content, successCallback) =>
              this.props.openConfModal(title, content, successCallback)
            }
          />
        );
      },
    );

    return (
      <React.Fragment>
        <table className="table tablee4mad mt-3">
          <thead>
            <tr>
              <th>
                <FormattedMessage id="Name" />
              </th>

              <th className="tdaction text-center col-2">
                <FormattedMessage id="Actions" />
              </th>
            </tr>
          </thead>
          <tbody>{deskProductRowNode}</tbody>
        </table>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeskProductsTableAdmin);
