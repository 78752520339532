import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Modal } from "react-bootstrap";

class ConfirmationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      mandatoryConfirmation: this.props.mandatoryConfirmation
        ? Math.floor(Math.random() * (999999 - 100001 + 1) + 100001).toString()
        : false,
      inputConfirmationData: "",
    };
  }

  close() {
    this.props.closeModal();
  }

  confirm() {
    // Little hack there. Should have used proper callbacks, but the modal was used everywhere,
    // so used async/await no to break the existing code.
    let callback = async () => {
      await this.props.successCallback();
      this.close();
    };

    this.setState({ disabled: true }, callback);
  }

  verifyRequiredInput(data) {
    this.setState({ inputConfirmationData: data });
  }

  render() {
    const {
      size,
      title,
      content,
      hideCancelButton,
      cancelButtonLabel,
      modalButtonConfirm,
    } = this.props;
    let context = this.props.context || "info";

    return (
      <Modal
        show={true}
        onHide={() => this.close()}
        size={size}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title bsPrefix={"modal-title text-" + context}>
            {title ? title : <FormattedMessage id="Confirm" />}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {content}

          {this.state.mandatoryConfirmation && (
            <p className="text-danger mt-2">
              <strong>
                <FormattedMessage
                  id="Mandatory.Confirmation.Message"
                  values={{ code: this.state.mandatoryConfirmation }}
                />
              </strong>
              <br />
              <input
                type="text"
                className="form-control border-danger mt-1"
                placeholder={this.props.intl.formatMessage({ id: "Code" })}
                value={this.state.inputConfirmationData}
                onChange={(e) => this.verifyRequiredInput(e.target.value)}
              />
            </p>
          )}
        </Modal.Body>

        <Modal.Footer>
          {!hideCancelButton && (
            <Button variant="secondary" onClick={() => this.close()}>
              {cancelButtonLabel ? (
                cancelButtonLabel
              ) : (
                <FormattedMessage id="Cancel" />
              )}
            </Button>
          )}
          <Button
            variant={"btn btn-" + context}
            onClick={() => this.confirm()}
            disabled={
              this.state.mandatoryConfirmation
                ? this.state.inputConfirmationData !==
                  this.state.mandatoryConfirmation
                : this.state.disabled
            }
          >
            {modalButtonConfirm ? (
              modalButtonConfirm
            ) : (
              <FormattedMessage id="Confirm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default injectIntl(ConfirmationModal);
