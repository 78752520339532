import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import TextareaCounter from "react-textarea-counter";
import Util from "../../util/Util";
import RequestTypes from "../../enums/RequestTypes";
import EstimateStatus from "../../enums/EstimateStatus";
import { createRequest } from "../../actions/requests/requests";
import CustomLabel from "../sub/CustomLabel";

class NewMaterialRequest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      precisions: "",
      disabled: false,
      disabledInputs: false,
    };

    if (this.props.request) {
      this.state = {
        method: this.props.request.method,
        precisions: this.props.request.precisions,
        disabled: false,
        disabledInputs: true,
      };
    }
  }

  create() {
    if (this.hasEmptyField() || this.state.disabled) return;

    let data = {
      request_type: RequestTypes.NEW_MATERIAL,
      method: parseInt(this.state.method),
      precisions: this.state.precisions,
      status: EstimateStatus.PENDING,
    };

    this.setState({ disabled: true });

    this.props.onCreateRequest(data, () => this.props.close());
  }

  hasEmptyField() {
    return Util.emptyString(this.state.precisions);
  }

  onChange(key, value) {
    this.setState({ [key]: value });
  }

  render() {
    const { intl, close } = this.props;

    return (
      <React.Fragment>
        <div className="form-group row">
          <CustomLabel
            label={intl.formatMessage({ id: "Precisions" })}
            htmlFor="precisions"
            labelClassName="col-12 col-sm-5 col-form-label"
            required
          />
          <div className="col-12 col-sm-7">
            <TextareaCounter
              countLimit={1000}
              className="textareaCounter"
              id="precisions"
              initialValue={this.state.precisions}
              onChange={(e) => this.onChange("precisions", e.target.value)}
              disabled={this.state.disabledInputs}
            />
          </div>
        </div>

        {!this.state.disabledInputs && (
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => close()}
            >
              <FormattedMessage id="Cancel" />
            </button>
            <button
              type="button"
              className="btn btn-info"
              onClick={() => this.create()}
              disabled={this.state.disabled || this.hasEmptyField()}
            >
              <FormattedMessage id="Pass" />
            </button>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateRequest: (data, successCallback) =>
      dispatch(createRequest(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(NewMaterialRequest));
