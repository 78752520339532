import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Modal } from "react-bootstrap";
import Util from "../../../util/Util";
import FileDropZone from "../FileDropZone";
import CustomLabel from "../CustomLabel";
import PropTypes from "prop-types";
import Icon from "../Icon";
import DocumentsDb from "../../documents/DocumentsDb";

const propTypes = {
  data: PropTypes.object.isRequired,
  documentType: PropTypes.string.isRequired,
  addDataDocument: PropTypes.any.isRequired,
  updateData: PropTypes.any.isRequired,
  close: PropTypes.any.isRequired,
  messageId: PropTypes.string.isRequired,
};

/**
 * Modal to add a document with title
 * @param {Array} data Object where document will be store
 * @param {String} documentType string to define what type of document it will be
 * @param {any} addDataDocument fonction to store file
 * @param {any} updateData fonction to update data
 * @param {any} close fonction to close modal
 * @param {string} messageId id for formatted message to display modal title
 */
class AddDocumentModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      label: "",
      file: null,
      disabled: false,
      modal: null,
      isUploadedFromDocumentDb: false,

      fileError: false,
    };
  }

  disabled() {
    return (
      Util.emptyString(this.state.label) ||
      !this.state.file ||
      this.state.disabled ||
      this.state.fileError
    );
  }

  onDropFile(file) {
    this.setState({ file: file });

    if (
      this.props.data[this.props.documentType].find(
        (p) => p.file_name === file.name,
      )
    ) {
      this.setState({
        fileError: (
          <FormattedMessage
            id="File.Already.Exists"
            values={{ fileName: file.name }}
          />
        ),
      });
    } else {
      this.setState({ fileError: false });
    }
  }

  onSubmit() {
    if (this.disabled()) return;

    const { data, documentType, updateData, addDataDocument } = this.props;

    if (!data) return;

    this.setState({ disabled: true });

    const successCallback = () => {
      let clonedData = Util.shallowClone(data);
      if (!clonedData[documentType]) clonedData[documentType] = [];

      clonedData[documentType].push({
        title: this.state.label,
        date: Date.now(),
        file_name: this.state.file.name,
      });

      const updatedData = {
        _id: clonedData._id,
        [documentType]: clonedData[documentType],
      };

      updateData(updatedData, () => this.props.close());
    };

    let formData = new FormData();
    formData.append(encodeURIComponent(this.state.file.name), this.state.file);
    addDataDocument(data._id, documentType, formData, successCallback);
  }

  addDocumentsDbFile() {
    const { data, documentType, updateData } = this.props;

    let clonedData = Util.shallowClone(data);
    if (!clonedData[documentType]) clonedData[documentType] = [];

    clonedData[documentType].push({
      title: this.state.label,
      date: Date.now(),
      file_name: this.state.file.file_name,
      is_shared_file: true,
      url: this.state.file.url,
    });

    const updatedData = {
      _id: clonedData._id,
      [documentType]: clonedData[documentType],
    };

    updateData(updatedData, () => this.props.close());
  }

  closeModal() {
    this.setState({ modal: null });
  }

  openDocumentsDbModal() {
    this.setState({
      modal: (
        <Modal
          show={true}
          onHide={() => this.closeModal()}
          backdrop="static"
          keyboard={false}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.intl.formatMessage({ id: "Documents" })}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DocumentsDb
              isUpload={true}
              setParentState={(file) =>
                this.setState({
                  file: file,
                  modal: null,
                  isUploadedFromDocumentDb: true,
                })
              }
              isPatient={true}
            />
          </Modal.Body>
        </Modal>
      ),
    });
  }

  render() {
    return (
      <Modal
        show={true}
        onHide={() => this.props.close()}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.props.intl.formatMessage({ id: this.props.messageId })}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group row">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Title" })}
              htmlFor="label"
              labelClassName="col-12 col-sm-5 col-form-label"
              required
            />
            <div className="col-12 col-sm-7">
              <input
                className="form-control"
                type="text"
                id="label"
                autoComplete="off"
                value={this.state.label}
                onChange={(e) => this.setState({ label: e.target.value })}
              />
            </div>
          </div>

          <div className="row justify-content-center mt-5">
            <p>
              <FormattedMessage id="Choose.Document.Source" />
            </p>
          </div>

          <div className="row justify-content-center align-items-center">
            <div
              role="button"
              className="col-sm-5"
              onClick={() => this.openDocumentsDbModal()}
              style={{
                width: "100%",
                borderWidth: "2px",
                borderColor: "rgb(102, 102, 102)",
                borderStyle: "dashed",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              <div className="text-center">
                <Icon
                  disabled={this.state.isUploadedFromDocumentDb}
                  icon="folder-open"
                  size="6x"
                />
              </div>
              <div className="small text-center">
                <span>
                  {this.state.isUploadedFromDocumentDb ? (
                    this.state.file.file_name
                  ) : (
                    <FormattedMessage id="Select.From.Shared.Documents" />
                  )}
                </span>
              </div>
            </div>

            <div className="col-sm-1 text-center">
              <h2>
                <strong className="text-uppercase">
                  <FormattedMessage id="Or" />
                </strong>
              </h2>
            </div>

            <div className="col-sm-5">
              <FileDropZone
                disabled={this.state.isUploadedFromDocumentDb}
                onDropFile={(file) => this.onDropFile(file)}
                acceptedExtensions={[
                  "webp",
                  "pdf",
                  "doc",
                  "docx",
                  "txt",
                  "jpg",
                  "jpeg",
                  "png",
                ]}
                multiple={false}
              />
            </div>
          </div>

          <div className="w-100 text-center">
            <small className="text-danger">{this.state.fileError}</small>
          </div>

          <div className="row mt-5">
            <div className="col-12 col-lg-6 offset-lg-3">
              <button
                className="btn btn-info btn-block"
                disabled={this.disabled()}
                onClick={() => {
                  this.state.isUploadedFromDocumentDb
                    ? this.addDocumentsDbFile()
                    : this.onSubmit();
                }}
              >
                <FormattedMessage id="Add" />
              </button>
            </div>
          </div>

          {this.state.modal}
        </Modal.Body>
      </Modal>
    );
  }
}

AddDocumentModal.propTypes = propTypes;

export default injectIntl(AddDocumentModal);
