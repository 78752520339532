import React from "react";
import { connect } from "react-redux";
import StringUtil from "../../../../util/StringUtil";
import { FormattedMessage } from "react-intl";
import {
  updateAuditRoom,
  deleteAuditRoom,
} from "../../../../actions/settings/auditRooms/admin/auditRooms";
import Util from "../../../../util/Util";
import Icon from "../../../sub/Icon.js";
import ActionMenu from "../../../sub/ActionMenu";
import MenuButton from "../../../sub/bootstrap/MenuButton";

class AuditRoomsRowAdmin extends React.Component {
  updateAuditRoom(auditRoomId, updatedField, updatedValue) {
    if (!auditRoomId) return;
    if (!this.props.auditRoom) return null;

    if (updatedField === "sortIndex" && Util.emptyString(updatedValue)) return;

    var data = {
      auditRoomId: auditRoomId,
      updatedField: updatedField,
      updatedValue: updatedValue + 1,
    };
    var successCallback = () => {
      var auditRooms = this.props.auditRooms;
      var auditRoomAscend = auditRooms.filter(
        (obj) => obj.sortIndex === updatedValue + 1,
      );
      data = {
        auditRoomId: auditRoomAscend[0]._id,
        updatedField: updatedField,
        updatedValue: updatedValue,
      };
      this.props.onUpdateAuditRoom(data, () => {});
    };
    this.props.onUpdateAuditRoom(data, successCallback);
  }

  deleteAuditRoom(auditRoomId, updatedField, updatedValue) {
    if (!auditRoomId) return;
    if (updatedField === "sortIndex" && Util.emptyString(updatedValue)) return;

    var data = {
      auditRoomId: auditRoomId,
      updatedField: updatedField,
      updatedValue: updatedValue,
    };
    var successCallback = () => {
      var auditRooms = this.props.auditRooms;
      var auditRoomsAscend = auditRooms.filter(
        (obj) => obj.sortIndex > updatedValue,
      );
      auditRoomsAscend.map((auditRoom) => {
        data = {
          auditRoomId: auditRoom._id,
          updatedField: updatedField,
          updatedValue: auditRoom.sortIndex - 1,
        };
        this.props.onUpdateAuditRoom(data, () => {
          return null;
        });
        return null;
      });
    };
    this.props.onDeleteAuditRoom(data, successCallback);
  }

  render() {
    let hidden = this.props.auditRoom.hidden || false;

    let auditRoomName = hidden ? (
      <span className="text-danger">
        {this.props.auditRoom.name.toUpperCase()} <Icon icon="eye-slash" />
      </span>
    ) : (
      this.props.auditRoom.name.toUpperCase()
    );

    // 'Delete audit room' modal setup
    var modalTitle = <FormattedMessage id="Confirm" />;
    var modalContent = (
      <React.Fragment>
        <FormattedMessage id="Room.Type.Remove.Confirmation" />
        <br />
        <br />
        <div className="card text-white bg-danger">
          <div className="card-header">
            <Icon icon="triangle-exclamation" className="mr-2 text-white" />
            <FormattedMessage id="Warning" />
          </div>
          <div className="card-body">
            <p className="card-text">
              <FormattedMessage id="AuditRoom.Delete.Warning.Content" />
            </p>
          </div>
        </div>
      </React.Fragment>
    );
    var successCallback = () =>
      this.deleteAuditRoom(
        this.props.auditRoom._id,
        "sortIndex",
        this.props.auditRoom.sortIndex,
      );

    var disabled =
      this.props.auditRooms.length <= this.props.auditRoom.sortIndex;

    let menuItems = [];

    menuItems.push(
      {
        icon: "arrow-down",
        action: () => {
          if (!disabled) {
            this.updateAuditRoom(
              this.props.auditRoom._id,
              "sortIndex",
              this.props.auditRoom.sortIndex,
            );
          }
        },
        text: <FormattedMessage id="Retrograde" />,
        disabled: disabled,
      },
      {
        icon: "pen-to-square",
        action: () => this.props.openEditModal(this.props.auditRoom),
        text: <FormattedMessage id="Modify" />,
      },
      {
        icon: "trash",
        action: () =>
          this.props.openConfModal(modalTitle, modalContent, successCallback),
        text: <FormattedMessage id="Delete" />,
      },
    );

    const menuAction = <ActionMenu items={menuItems} />;

    return (
      <React.Fragment>
        <tr
          key={this.props.key}
          className="order-tr order-tr tablelist row-striped"
        >
          <td>{auditRoomName}</td>
          <td>
            {this.props.auditRoom.default_text &&
              StringUtil.truncate(this.props.auditRoom.default_text, 150)}
          </td>

          <td className="text-center">
            <MenuButton
              icon="eye"
              onClick={(e) =>
                this.props.displaysDmAssociatedModal(this.props.auditRoom)
              }
              hover={<FormattedMessage id="Display.Associations" />}
            />
          </td>

          <td className="text-center">{menuAction}</td>
        </tr>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auditRooms: state.auditRooms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteAuditRoom: (data, successCallback) =>
      dispatch(deleteAuditRoom(data, successCallback)),
    onUpdateAuditRoom: (data, successCallback) =>
      dispatch(updateAuditRoom(data, successCallback)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditRoomsRowAdmin);
