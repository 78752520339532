import React from "react";
import { connect } from "react-redux";
import PDFUtil from "../../util/PDFUtil";
import DateUtil from "../../util/DateUtil";
import { FormattedMessage, injectIntl } from "react-intl";
import OrderProducts from "./OrderProducts";
import OrderPDF from "./OrderPDF";
import PDFViewer from "../pdf/PDFViewer";
import OrderStatus from "../../enums/OrderStatus";
import { duplicate } from "../../actions/orders/orders";
import Icon from "../sub/Icon.js";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import Maths from "../../util/Maths";
import ActionMenu from "../sub/ActionMenu";

class OrderRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
      displayPDF: false,
      disableExport: false,
      disabledDuplicat: false,
    };
  }

  collapse() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  hasUrgent(products) {
    for (let product of products) {
      if (product.urgent) return true;
    }

    return false;
  }

  canPDF(order) {
    return (
      order.status === OrderStatus.PENDING ||
      order.status === OrderStatus.SENT ||
      order.status === OrderStatus.NEW ||
      order.status === OrderStatus.BLOCKED
    ); //|| order.status === Role.RECEIVED
  }

  togglePDF(pdfElemId, pdfFileName) {
    if (this.state.disableExport || !this.canPDF(this.props.order)) return;
    if (process.env.REACT_APP_PDF_OVERVIEW === "true") {
      // Possibility n°1 (dev env) : display preview of PDF (without header, footer, pages etc.)
      this.setState({ displayPDF: !this.state.displayPDF });
    } else {
      // Possibility n°2 (prod env) : directly save to PDF
      this.setState({ disableExport: true });
      const successCallback = () => this.setState({ disableExport: false });
      PDFUtil.toPDF(
        this.props.generalSettings,
        this.props.company,
        pdfElemId,
        pdfFileName,
        "/css/orderPdf.css",
        successCallback,
      );
    }
  }

  duplicate(orderId) {
    if (this.state.disabledDuplicat) return;

    this.setState({ disabledDuplicat: true });

    const successCallback = () => {
      let message = this.props.intl.formatMessage({ id: "Cart.duplicate" });

      NotificationManager.info(message);

      this.setState({ disabledDuplicat: false });
    };

    this.props.onDuplicate(orderId, successCallback);
  }

  isMercurialActive(mercurialId) {
    return mercurialId === this.props.user.mercurial_Id;
  }

  render() {
    const orderHasProducts = this.props.order.products.length > 0;
    const pdfElemId = "order-pdf-" + this.props.order._id;
    const pdfFileName = "order-" + this.props.order._id;
    let pdfOrder = null;

    if (orderHasProducts) {
      pdfOrder = (
        <OrderPDF
          id={pdfElemId}
          order={this.props.order}
          admin={this.props.admin}
          client={this.props.user}
        />
      );
    }

    let orderStatusClassName = "light";
    if (orderHasProducts) {
      switch (parseInt(this.props.order.status)) {
        case OrderStatus.NEW:
          orderStatusClassName = "danger";
          break;
        case OrderStatus.PENDING:
          orderStatusClassName = "info";
          break;
        case OrderStatus.SENT:
          orderStatusClassName = "success";
          break;
        case OrderStatus.BLOCKED:
          orderStatusClassName = "warning";
          break;
        case OrderStatus.CANCELED:
          orderStatusClassName = "secondary";
          break;
        default:
          break;
      }
    }

    const isDuplicable =
      this.isMercurialActive(this.props.order.mercurial._id) &&
      this.props.order.mercurial.status !== OrderStatus.PENDING &&
      !this.state.disabledDuplicat &&
      new Date(this.props.order.mercurial.end_date).getTime() >= Date.now();

    let menuItems = [];

    menuItems.push(
      // Show/hide products
      orderHasProducts && {
        id: "collapse" + this.props.order._id,
        icon: this.state.collapsed ? "eye" : "eye-slash",
        disabled: !orderHasProducts,
        action: () => orderHasProducts && this.collapse(),
        text: this.state.collapsed ? (
          <FormattedMessage id="Display.Product.Order" />
        ) : (
          <FormattedMessage id="Hide.Product.Order" />
        ),
      },
      // Download PDF
      orderHasProducts && {
        id: "downloadPdf" + this.props.order._id,
        icon: "file-pdf",
        disabled: !orderHasProducts,
        action: () =>
          orderHasProducts && this.togglePDF(pdfElemId, pdfFileName),
        text: <FormattedMessage id="Download.PDF" />,
      },
      // Duplicate order
      orderHasProducts &&
        isDuplicable && {
          id: "duplicate" + this.props.order._id,
          icon: "clone",
          disabled: !orderHasProducts,
          action: () =>
            orderHasProducts &&
            isDuplicable &&
            this.duplicate({ orderId: this.props.order._id }),
          text: <FormattedMessage id="Duplicate.Order.To.New.Order" />,
        },
    );

    const creationDate = DateUtil.toDateWithHour(
      this.props.order.creation_date,
    );
    const deliveryDate = DateUtil.toDate(
      new Date(this.props.order.creation_date).getTime() +
        this.props.order.shipping_delay * 24 * 3600 * 1000,
    );

    return (
      <>
        <tr
          key={this.props.order._id}
          className={
            !orderHasProducts
              ? "d-flex font-italic text-muted align-items-center justify-content-center"
              : "d-flex align-items-center justify-content-center"
          }
        >
          <td className="col">
            {this.props.order.ref}
            {this.props.order.comment && this.props.comment !== "" && (
              <span>
                <Icon
                  icon="comments"
                  size="xl"
                  className="float-right text-info"
                  hover={
                    <>
                      <FormattedMessage id="Available.Comment" /> :<br />
                      {this.props.order.comment}
                    </>
                  }
                />
              </span>
            )}
          </td>
          <td className="col">{creationDate}</td>
          <td className="col">{deliveryDate}</td>
          <td className="col col-1">
            {Maths.round(this.props.order.total_ttc)}€
          </td>
          <td className={!this.props.limit ? "col col-2" : "col col-3"}>
            <div className={"alert alert-" + orderStatusClassName}>
              <FormattedMessage
                id={"Order.Status." + this.props.order.status}
              />
            </div>
          </td>
          {!this.props.limit && (
            <td className="col-1 text-center">
              {
                <ActionMenu
                  items={menuItems}
                  variant={
                    orderHasProducts &&
                    this.hasUrgent(this.props.order.products)
                      ? "danger"
                      : "info"
                  }
                />
              }
              {
                <PDFViewer
                  open={this.state.displayPDF}
                  toggle={(pdfElemId, pdfFileName) => {
                    if (orderHasProducts)
                      this.togglePDF(pdfElemId, pdfFileName);
                  }}
                  template={pdfOrder}
                  fileName={pdfFileName}
                />
              }
            </td>
          )}
        </tr>

        {!this.state.collapsed && (
          <tr className="d-flex">
            <td colSpan="7" className="col p-1">
              <OrderProducts order={this.props.order} paginate={true} />
            </td>
          </tr>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    generalSettings: state.generalSettings,
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDuplicate: (data, successCallback) =>
      dispatch(duplicate(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(OrderRow));
