import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import RequestStatus from "../../enums/RequestStatus";
import RequestTypes from "../../enums/RequestTypes";
import EstimateStatus from "../../enums/EstimateStatus";
import Roles from "../../enums/Roles";
import DateUtil from "../../util/DateUtil";
import { updateRequest, deleteRequest } from "../../actions/requests/requests";
import ConfirmationModal from "../sub/modals/ConfirmationModal";
import Icon from "../sub/Icon.js";
import ActionMenu from "../sub/ActionMenu";

class RequestRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  openRequestModalAdmin(request) {
    this.props.openRequestModalAdmin(request);
  }

  openRequestDetailModal(request) {
    this.props.openRequestDetailModal(request);
  }

  deleteRequestAdmin(request) {
    const deleteCbk = () =>
      this.props.onDeleteRequest({ requestId: request._id });

    this.setState({
      modal: (
        <ConfirmationModal
          isOpen={true}
          title={<FormattedMessage id={"Confirm"} />}
          content={<FormattedMessage id={"Request.Remove.Confirmation"} />}
          successCallback={deleteCbk}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  updateRequest(e, request) {
    var data = {
      updatedValue: e.target.value,
      updatedField: "status",
      requestId: request._id,
    };

    this.props.onUpdateRequest(data);
  }

  getStatusNode(items, type) {
    let idFormatMessage = "Request.Status.";

    if (type === "estimate") idFormatMessage = "Estimate.Status.";

    return Object.values(items).map((item) => {
      return (
        <option key={item} value={item}>
          {this.props.intl.formatMessage({ id: idFormatMessage + item })}
        </option>
      );
    });
  }

  render() {
    const {
      user,
      request,
      intl,
      key,
      clientName,
      includeType,
      materialName,
      limit,
    } = this.props;

    let isClient = user.role === Roles.CLIENT;
    let status = <FormattedMessage id={"Request.Status." + request.status} />;

    // By default, show requests status
    let statusNode = this.getStatusNode(RequestStatus, "request");

    if (request.request_type === RequestTypes.NEW_MATERIAL) {
      statusNode = this.getStatusNode(EstimateStatus, "estimate");
    }

    let statusClassName = "light";
    if (request.request_type === RequestTypes.NEW_MATERIAL) {
      if (parseInt(request.status) === 0) statusClassName = "success";
      if (parseInt(request.status) === 1) statusClassName = "warning";
      else if (parseInt(request.status) === 2) statusClassName = "info";
      else if (parseInt(request.status) === 3) statusClassName = "danger";

      status = (
        <div className={"alert alert-" + statusClassName}>
          &nbsp;
          {intl.formatMessage({ id: "Estimate.Status." + request.status })}
          &nbsp;
        </div>
      );
    }
    let menuItems = [];

    isClient &&
      menuItems.push({
        icon: "eye",
        action: () => this.openRequestDetailModal(request),
        text: <FormattedMessage id="Consult.Request" />,
      });

    /*
        menuItems.push({
            icon: "download",
            link: APIUrl.getRequestFile + request._id + "/" + request.files[request.files.length - 1] + "?token=" + APIUrl.jwtToken,
            text: <FormattedMessage id="Download.Attachment" />,
            disabled: (request && (!request.files || request.files.length < 1))
        });
        */

    !isClient &&
      menuItems.push({
        icon: "pen-to-square",
        action: () => this.openRequestModalAdmin(request),
        text: <FormattedMessage id="Modify" />,
      });

    !isClient &&
      menuItems.push({
        icon: "trash",
        action: () => this.deleteRequestAdmin(request),
        text: <FormattedMessage id="Delete" />,
      });

    const menuAction = (
      <ActionMenu
        icon={
          request && request.files && request.files.length > 0 && "download"
        }
        items={menuItems}
      />
    );

    return (
      <tr key={key} className={isClient ? "d-flex" : ""}>
        {!isClient && (
          <td className="align-middle">
            <span className="d-block d-sm-none">{clientName}</span>
            <span className="d-none d-sm-table-cell">{clientName}</span>
          </td>
        )}

        {includeType && (
          <td
            className={isClient ? "col align-middle" : "d-none d-sm-table-cell"}
          >
            <FormattedMessage id={"Request.Type." + request.request_type} />
          </td>
        )}

        {request.request_type !== RequestTypes.NEW_MATERIAL && (
          <td className={isClient ? "col" : "d-none d-sm-table-cell"}>
            {materialName}
          </td>
        )}

        <td className={isClient ? "col align-middle" : "align-middle"}>
          <div className="truncate">{request.precisions}</div>
        </td>

        <td className={isClient ? "col col-2 align-middle" : "align-middle"}>
          {DateUtil.toDate(request.creation_date)}
        </td>

        {isClient ? (
          <td
            className={
              !limit ? "col col-2 align-middle" : "col col-3 align-middle"
            }
          >
            {status}
          </td>
        ) : (
          <td className="align-middle">
            <select
              className={"form-control alert-" + statusClassName}
              onChange={(e) => this.updateRequest(e, request)}
              defaultValue={request.status}
            >
              {statusNode}
            </select>
            <span className="d-block d-sm-none mt-2 text-left tdaction">
              <Icon
                icon="pen-to-square"
                clickable
                size="xl"
                className="hoverable"
                onClick={(e) => this.openRequestModalAdmin(this.props.request)}
              />
            </span>
          </td>
        )}

        {!limit && (
          <td className={isClient ? "col-1 text-center" : "text-center"}>
            {menuAction}
          </td>
        )}

        {this.state.modal}
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateRequest: (data) => dispatch(updateRequest(data)),
    onDeleteRequest: (requestId) => dispatch(deleteRequest(requestId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(RequestRow));
